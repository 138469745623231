import { Alert, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { getOrder, getOrderComments, getOrderHistory } from "../../../api/orders";
import Loader from "../../../components/Loader";
import OrderDetailsHeader from "./Header";
import OrderForm from "./Form";
import DetailsTable from "./DetailsTable";
import { OrderStatus } from "../../../types/orders";
import OrderTimeline from "./Timeline";
import { isOrderActionAuthorized } from "../../../utils/auth";
import { OrdersActions } from "../../../types/auth";
import OrderComment from "./Comments";
import toast from "react-hot-toast";
import { useSites } from "../../../contexts/SiteContext";

function OrderDetails() {
  const { id } = useParams<"id">() as { id: string };
  const { meetingPoints } = useSites();

  const {
    data: orderData,
    isLoading: isOrderLoading,
    isFetching: isOrderFetching,
    refetch: refetchOrder,
  } = useQuery(["order", id], () => getOrder(id), {
    staleTime: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: () => toast.error("an error occurred while retrieving order data"),
  });

  const {
    data: historyData,
    isError: isHistoryError,
    isLoading: isHistoryLoading,
    refetch: refetchHistory,
  } = useQuery(["history", id], () => getOrderHistory(id, meetingPoints), {
    enabled: !!meetingPoints.length,
  });

  const {
    data: commentsData,
    isError: isCommentsError,
    isLoading: isCommentsLoading,
    refetch: refetchComments,
  } = useQuery(["comments", id], () => getOrderComments(id));

  const queryClient = useQueryClient();

  const isEditable =
    orderData?.status === OrderStatus.Accepted && isOrderActionAuthorized(OrdersActions.Edit);

  const refetch = () => {
    refetchOrder();
    refetchHistory();
    refetchComments();
    queryClient.invalidateQueries("startSlots");
    queryClient.invalidateQueries("endSlots");
  };

  if (isOrderLoading) {
    return <Loader />;
  }
  if (orderData) {
    return (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <OrderDetailsHeader
            orderData={orderData}
            isFetching={isOrderFetching}
            refetch={refetch}
            isEditable={isEditable}
          />
        </Grid>
        <Grid item xs={12}>
          <DetailsTable row={orderData} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <OrderForm orderData={orderData} isEditable={isEditable} />
        </Grid>
        <Grid item xs={12} lg={4}>
          <OrderComment
            commentsData={commentsData}
            orderId={orderData.id}
            isLoading={isCommentsLoading}
            isError={isCommentsError}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <OrderTimeline
            orderData={orderData}
            historyData={historyData}
            isError={isHistoryError}
            isLoading={isHistoryLoading}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Alert severity="error">
      No data for order
      {id}
    </Alert>
  );
}

export default OrderDetails;
