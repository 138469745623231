import { FocusEvent } from "react";
import { Person } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { getDiffWithSymbol } from "../../../../utils/text";

interface PassengersInputProps {
  onChange: (e: SelectChangeEvent) => void;
  handleBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isEditable: boolean;
  value: number | undefined;
  errorMessage: string | undefined;
  currentPassengers: number;
}

const PassengersInput = ({
  onChange,
  handleBlur,
  value,
  errorMessage,
  isEditable,
  currentPassengers,
}: PassengersInputProps) => {
  const generatePassengersOptions = (passengersList: number[]) => {
    return passengersList.map((number) => {
      const getDiffLabel = () => {
        if (!!currentPassengers && number !== currentPassengers) {
          const unit =
            currentPassengers - number < -1 || currentPassengers - number > 1
              ? "passengers"
              : "passenger";
          return `(${getDiffWithSymbol(currentPassengers, number)} ${unit})`;
        } else return "";
      };

      return (
        <MenuItem value={number} key={number}>
          {`${number} ${getDiffLabel()}`}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl fullWidth variant="standard" error={!!errorMessage}>
      <InputLabel id="passenger-select">Passengers</InputLabel>
      <Select
        readOnly={!isEditable}
        IconComponent={() => <Person color="action" sx={{ transform: "inherit" }} />}
        inputProps={{ name: "passengers" }}
        value={value ? `${value}` : ""}
        onChange={onChange}
        onBlur={handleBlur}
      >
        {generatePassengersOptions([1, 2, 3])}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default PassengersInput;
