import React from "react";
import styled from "styled-components/macro";
import { Button, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Link } from "react-router-dom";

const Text = styled(Typography)`
  text-decoration: underline;
  color: ${blue[500]};
`;

interface CellPropsType {
  value: string;
  orderId: string | number;
}

function CustomCellId({ value, orderId }: CellPropsType) {
  return (
    <Link to={`/orders/${orderId}`}>
      <Button>
        <Text>{value}</Text>
      </Button>
    </Link>
  );
}

export default CustomCellId;
