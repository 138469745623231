import { useState } from "react";
import { Button, Grid, Paper } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Replay } from "@mui/icons-material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import OperationForm from "../../../../components/form/BookingForm";
import CustomerForm from "../../../../components/form/CustomerForm";
import { Slot, SlotType } from "../../../../types/sites";
import { CreateOrderFormikValues, Order, OrderFormikValues } from "../../../../types/orders";
import { useMutation, useQueryClient } from "react-query";
import { postOrder, postOrderPlatform } from "../../../../api/orders";
import { labelLanguage } from "../../../../config";
import toast from "react-hot-toast";
import { NoUndefinedField } from "../../../../types/utils";
import PlatformForm from "../../../../components/form/PlatformForm";

interface CreateOrderMutateVariable {
  postQuery: () => Promise<Order>;
  resetForm: FormikHelpers<CreateOrderFormikValues>["resetForm"];
  validateForm: FormikHelpers<CreateOrderFormikValues>["validateForm"];
}

const OrderCreationForm = () => {
  const { t } = useTranslation();
  const [siteStartSlots, setSiteStartSlots] = useState<Array<Slot>>([]);
  const [siteEndSlots, setSiteEndSlots] = useState<Array<Slot>>([]);

  const queryClient = useQueryClient();

  const orderCreationToastId = "orderCreation";
  const { mutate } = useMutation(
    "createOrder",
    ({ postQuery }: CreateOrderMutateVariable) => postQuery(),
    {
      onMutate: () => {
        toast.loading(`Order creation in progress`, {
          id: orderCreationToastId,
        });
      },
      onSuccess: (data, { resetForm }) => {
        resetForm();
        toast.success(`order ${data.number} successfully created`, {
          id: orderCreationToastId,
        });
        queryClient.invalidateQueries(["orders"]);
        queryClient.invalidateQueries(["startSlots"]);
        queryClient.invalidateQueries(["endSlots"]);
      },
      onError: (error: string | object, { validateForm }) => {
        let errorMessage = error;
        if (typeof error === "object") {
          if (Object.prototype.hasOwnProperty.call(error, "number")) {
            const { number } = error as { number: string };
            errorMessage = `An order already exist with the number ${number}`;
          } else if (
            Object.prototype.hasOwnProperty.call(error, "startDate") ||
            Object.prototype.hasOwnProperty.call(error, "endDate")
          ) {
            errorMessage = `The current selected slots doesn't have sufficient passengers available`;
            const startSlotsRefetch = queryClient.invalidateQueries(["startSlots"]);
            const endSlotsRefetch = queryClient.invalidateQueries(["endSlots"]);
            Promise.allSettled([startSlotsRefetch, endSlotsRefetch]).then(() => {
              // little timeout to wait that setSiteStartSlots and setSiteEndSlots as set correctly the new fresh data.
              // otherwise validatePassengersAvailability will not have correct slotsData
              setTimeout(validateForm, 500);
            });
          }
        }
        toast.error(errorMessage as string, {
          id: orderCreationToastId,
          duration: 12000,
        });
      },
    }
  );

  const initialValues: CreateOrderFormikValues = {
    [OrderFormikValues.siteId]: undefined,
    [OrderFormikValues.departureMeetingPointId]: undefined,
    [OrderFormikValues.arrivalMeetingPointId]: undefined,
    [OrderFormikValues.passengers]: undefined,
    [OrderFormikValues.arrivalFlightNumber]: undefined,
    [OrderFormikValues.startDate]: undefined,
    [OrderFormikValues.endDate]: undefined,
    [OrderFormikValues.selectedStartDay]: undefined,
    [OrderFormikValues.selectedEndDay]: undefined,
    [OrderFormikValues.firstName]: undefined,
    [OrderFormikValues.lastName]: undefined,
    [OrderFormikValues.email]: undefined,
    [OrderFormikValues.phone]: undefined,
    [OrderFormikValues.zipCode]: undefined,
    [OrderFormikValues.address]: undefined,
    [OrderFormikValues.city]: undefined,
    [OrderFormikValues.vehicleModel]: undefined,
    [OrderFormikValues.vehicleColor]: undefined,
    [OrderFormikValues.cancellationOption]: false,
    [OrderFormikValues.lang]: labelLanguage,
    [OrderFormikValues.isPlatform]: true,
    [OrderFormikValues.platformId]: undefined,
    [OrderFormikValues.externalOrderNumber]: undefined,
  };

  const validatePassengersAvailability = (
    selectedSlotStart: string | undefined,
    selectedPassengers: number | undefined,
    slotType: SlotType
  ) => {
    const slotsData = slotType === SlotType.Departure ? siteStartSlots : siteEndSlots;
    const availablePassengers = slotsData?.find(
      (slot) => slot.start === selectedSlotStart
    )?.availablePassengers;
    if (!selectedPassengers || !selectedSlotStart) return true;
    if (selectedSlotStart && availablePassengers && selectedPassengers) {
      return selectedPassengers <= availablePassengers;
    } else {
      return false;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        [OrderFormikValues.siteId]: Yup.number().required(t("form.fieldError.fieldRequired")),
        [OrderFormikValues.departureMeetingPointId]: Yup.number().required(
          t("form.fieldError.fieldRequired")
        ),
        [OrderFormikValues.arrivalMeetingPointId]: Yup.number().required(
          t("form.fieldError.fieldRequired")
        ),
        [OrderFormikValues.startDate]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "startDatePassengersAvailability",
            t("form.fieldError.passengersAvailabilityExceeded"),
            function (value) {
              return validatePassengersAvailability(
                value,
                this.parent.passengers,
                SlotType.Departure
              );
            }
          ),
        [OrderFormikValues.endDate]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "endDatePassengersAvailability",
            t("form.fieldError.passengersAvailabilityExceeded"),
            function (value) {
              return validatePassengersAvailability(
                value,
                this.parent.passengers,
                SlotType.Arrival
              );
            }
          ),
        [OrderFormikValues.selectedStartDay]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "selectedStartDayIsBeforeSelectedEndDay",
            t("form.fieldError.inAfterOut"),
            function (value) {
              if (this.parent.selectedEndDay) {
                return moment(value).isSameOrBefore(moment(this.parent.selectedEndDay));
              } else return true;
            }
          ),
        [OrderFormikValues.selectedEndDay]: Yup.string()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "selectedStartDayIsAfterSelectedEndDay",
            t("form.fieldError.outBeforeIn"),
            function (value) {
              if (this.parent.selectedStartDay) {
                return moment(value).isSameOrAfter(moment(this.parent.selectedStartDay));
              } else return true;
            }
          ),
        [OrderFormikValues.passengers]: Yup.number()
          .required(t("form.fieldError.fieldRequired"))
          .test(
            "passengersSlotsAvailability",
            t("form.fieldError.passengersAvailabilityExceeded"),
            function (value) {
              return (
                !!value &&
                (validatePassengersAvailability(this.parent.startDate, value, SlotType.Departure) ||
                  validatePassengersAvailability(this.parent.endDate, value, SlotType.Arrival))
              );
            }
          ),
        [OrderFormikValues.arrivalFlightNumber]: Yup.mixed().test(
          "flightNumberNullAuthorized",
          t("form.fieldError.fieldRequired"),
          (value) => !(value === null && initialValues.arrivalFlightNumber)
        ),
        [OrderFormikValues.firstName]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.lastName]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.email]: Yup.string()
          .email(t("form.fieldError.invalidEmailAddress"))
          .when([OrderFormikValues.isPlatform], {
            is: false,
            then: (shema) => shema.required(t("form.fieldError.fieldRequired")),
          }),
        [OrderFormikValues.phone]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.zipCode]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.address]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.city]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.vehicleModel]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.vehicleColor]: Yup.string().when("isPlatform", {
          is: true,
          then: Yup.string().nullable(true).optional(),
          otherwise: Yup.string().required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.isPlatform]: Yup.boolean().required(t("form.fieldError.fieldRequired")),
        [OrderFormikValues.platformId]: Yup.number().when([OrderFormikValues.isPlatform], {
          is: true,
          then: (shema) => shema.required(t("form.fieldError.fieldRequired")),
        }),
        [OrderFormikValues.externalOrderNumber]: Yup.string().when([OrderFormikValues.isPlatform], {
          is: true,
          then: (shema) => shema.required(t("form.fieldError.fieldRequired")),
        }),
      })}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, resetForm, validateForm }) => {
        const { selectedStartDay, selectedEndDay, isPlatform, ...platformQueryParams } =
          values as NoUndefinedField<CreateOrderFormikValues>;
        const { platformId, externalOrderNumber, ...b2cQueryParams } = platformQueryParams;

        const postQuery = isPlatform
          ? () => postOrderPlatform({ ...platformQueryParams })
          : () => postOrder({ ...b2cQueryParams });

        mutate(
          { postQuery, resetForm, validateForm },
          {
            onSettled: () => setSubmitting(false),
          }
        );
      }}
    >
      {(props) => (
        <Paper sx={{ height: "100%", display: "flex", flexDirection: "row" }}>
          <Grid container justifyContent="space-around">
            <Grid item xs={12}>
              <PlatformForm />
            </Grid>
            <Grid item xs={12} lg={6}>
              <OperationForm
                setSiteStartSlots={setSiteStartSlots}
                setSiteEndSlots={setSiteEndSlots}
                isRestrictedFieldsEditable={true}
                isEditable
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ display: "flex", alignItems: "center" }}>
              <CustomerForm
                isRestrictedFieldsEditable={true}
                isEmailOptional={props.values.isPlatform}
                isEditable
              />
            </Grid>
            <Grid xs={12} item py={4} display="flex" alignItems="center" justifyContent="center">
              <Button
                onClick={() => props.resetForm()}
                variant="outlined"
                sx={{ height: 30, mx: 3 }}
                disabled={!props.dirty || props.isSubmitting}
              >
                <Replay />
              </Button>
              <LoadingButton
                disabled={!props.dirty || !props.isValid}
                onClick={() => props.handleSubmit()}
                variant="contained"
                color="success"
                loading={props.isSubmitting}
              >
                Create order
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Formik>
  );
};

export default OrderCreationForm;
