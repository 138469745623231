import React from "react";
import { Typography } from "@mui/material";

const textStyle = { fontSize: 14, fontWeight: "bold", p: 3, textAlign: "center" };

const NotAllowed = () => (
  <Typography sx={textStyle}>You don't have permission for this.</Typography>
);

export default NotAllowed;
