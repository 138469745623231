import { Typography, Container } from "@mui/material";
import { OrderComment } from "../../../../types/orders";

interface CommentProps {
  commentData: OrderComment;
}

const Comment = ({ commentData }: CommentProps) => {
  return (
    <Container
      key={commentData.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        m: 2,
        width: "fit-content",
      }}
    >
      <Typography color="text.secondary" fontStyle="italic">
        {commentData.author}
      </Typography>
      <Typography
        sx={{
          bgcolor: "#3b608b",
          color: "white",
          borderRadius: 3,
          py: 2,
          px: 3,
          width: "100%",
          whiteSpace: "pre-line",
        }}
        fontWeight="bold"
        fontSize={14}
      >
        {commentData.text}
      </Typography>
      <Typography sx={{ alignSelf: "end" }} color="text.secondary" fontStyle="italic">
        {commentData.createdAt}
      </Typography>
    </Container>
  );
};

export default Comment;
