import { Typography } from "@mui/material";
import { Moment } from "moment";
import { dateTimeFormat } from "../../utils/date";

interface CellPropsType {
  value: Moment;
}

function CustomCellDateTime({ value }: CellPropsType) {
  return <Typography>{value.format(dateTimeFormat)}</Typography>;
}

export default CustomCellDateTime;
