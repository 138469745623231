import styled from "styled-components/macro";
import { Button, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useAppDispatch } from "../../hooks/redux";
import {
  setDebouncedSearchValue,
  setReplaceSearchValueByDebounced,
} from "../../redux/slices/ordersList";
import { batch } from "react-redux";

const Text = styled(Typography)`
  text-decoration: underline;
  color: ${blue[500]};
  width: 100%;
  overflow: hidden;
  text-align: left;
`;
interface CellPropsType {
  value: string;
}

function CustomCellEmail({ value }: CellPropsType) {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    batch(() => {
      dispatch(setDebouncedSearchValue(value));
      dispatch(setReplaceSearchValueByDebounced(true));
    });
  };

  return (
    <Button onClick={handleClick}>
      <Text>{value}</Text>
    </Button>
  );
}

export default CustomCellEmail;
