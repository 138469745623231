import { useNavigate } from "react-router-dom";
import { Moment } from "moment";
import { DataGridPro as MuiDataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { OrderFields } from "../../../../types/orders";
import { Movement } from "../../../../types/movements";
import CustomCellId from "../../../../components/customCell/Id";
import CustomCellMovementType from "../../../../components/customCell/MovementType";
import CustomCellPassengers from "../../../../components/customCell/Passengers";
import CustomToolbar from "./CustomToolbar";
import CustomCellArrivalFlight from "../../../../components/customCell/ArrivalFlight";
import styled from "styled-components";
import CustomCellDateTime from "../../../../components/customCell/DateTime";
import { labelLanguage } from "../../../../config";

interface MovementsListProps {
  movementsData: Movement[];
  isLoading: boolean;
  isFetching: boolean;
  fetchNextPage: () => void;
  refetch: () => void;
  hasNextPage: boolean;
}

const DataGridPro = styled(MuiDataGridPro)`
  background-color: ${(props) => props.theme.palette.background.paper};
  min-height: 600px;
  .MuiDataGrid-row {
    &.IN {
      background-color: ${(props) => props.theme.palette.transparency.green};
    }
    &.OUT {
      background-color: ${(props) => props.theme.palette.transparency.red};
    }
  }
`;

const MovementsList = ({
  movementsData,
  isLoading,
  isFetching,
  fetchNextPage,
  refetch,
}: MovementsListProps) => {
  const navigate = useNavigate();

  const column: GridColumns = [
    {
      field: "movementDate",
      headerName: "Hour",
      width: 90,
      align: "center",
      filterable: false,
      renderCell: (params) => params.value?.format("HH:mm"),
    },
    {
      field: "movementType",
      headerName: "Type",
      width: 90,
      align: "center",
      filterable: false,
      renderCell: (params) => <CustomCellMovementType value={params.value} />,
      sortable: false,
    },
    {
      field: OrderFields.passengers,
      headerName: "Passengers",
      align: "center",
      width: 80,
      filterable: false,
      renderCell: (params) => <CustomCellPassengers value={params.value} />,
      sortable: false,
    },
    {
      field: OrderFields.meetingPoint,
      headerName: "Meeting Point",
      width: 140,
      renderCell: (params) => params.value?.name[labelLanguage],
      sortable: false,
    },
    {
      field: OrderFields.lastName,
      headerName: "LastName",
      width: 140,
      sortable: false,
    },
    {
      field: OrderFields.firstName,
      headerName: "FirstName",
      width: 140,
      sortable: false,
    },
    {
      field: OrderFields.number,
      headerName: "ID",
      width: 110,
      renderCell: (params) => <CustomCellId value={params.value} orderId={params.row.orderId} />,
      sortable: false,
    },
    {
      field: OrderFields.phone,
      headerName: "Phone",
      width: 140,
      sortable: false,
    },
    {
      field: OrderFields.arrivalFlightNumber,
      headerName: "Arrival Flight",
      align: "center",
      width: 120,
      filterable: false,
      renderCell: (params) => <CustomCellArrivalFlight value={params.value} />,
      sortable: false,
    },
    {
      field: OrderFields.endDate,
      headerName: "OUT",
      width: 150,
      sortable: false,
      renderCell: ({ value }: { value: Moment }) => <CustomCellDateTime value={value} />,
    },
    {
      field: OrderFields.vehicleModel,
      headerName: "Vehicle Model",
      width: 150,
      sortable: false,
    },
    {
      field: OrderFields.vehicleColor,
      headerName: "Vehicle Color",
      width: 100,
      sortable: false,
    },
  ];

  return (
    <DataGridPro
      hideFooter
      rows={movementsData}
      columns={column}
      loading={isLoading || isFetching}
      disableSelectionOnClick
      onRowDoubleClick={(params) => navigate(`/orders/${params.row.orderId}`)}
      onRowsScrollEnd={() => fetchNextPage()}
      disableColumnMenu
      disableColumnReorder
      sortModel={[{ field: "movementDate", sort: "asc" }]}
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: { refetch, isLoading, isFetching },
      }}
      getRowClassName={(params) => params.row.movementType}
    />
  );
};

export default MovementsList;
