import i18n from "i18next";
import Backend from "i18next-fetch-backend";
import { initReactI18next } from "react-i18next";
import { mainLanguage } from "./config";
import en from "../public/static/i18n/en/main.json";

declare module "react-i18next" {
  interface CustomTypeOptions {
    defaultNS: "main";
    resources: {
      main: typeof en;
    };
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: mainLanguage,
    fallbackLng: mainLanguage,
    ns: ["main"],
    defaultNS: "main",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/static/i18n/{{lng}}/{{ns}}.json",
    },
  });
