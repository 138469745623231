import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface ConfirmDialogProps {
  title: string;
  content?: string;
  onConfirm: () => void;
  onClose: () => void;
  open: boolean;
}

export default function ConfirmDialog({
  title,
  content = "Are you sure you want to perform this action ? 🤔",
  onConfirm,
  onClose,
  open,
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: "bold" }}>{title}</DialogTitle>
      <DialogContent sx={{ whiteSpace: "pre-line" }}>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} sx={{ mr: 3 }}>
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          Yes, I want to
        </Button>
      </DialogActions>
    </Dialog>
  );
}
