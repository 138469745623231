import { Send } from "@mui/icons-material";
import { Grid, TextField, IconButton as MuiIconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "react-query";
import { postOrderComment } from "../../../../api/orders";

interface CommentProps {
  orderId: string;
}

const IconButton = styled(MuiIconButton)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
    filter: brightness(80%);
  }
  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.7;
  }
`;

const CommentInput = ({ orderId }: CommentProps) => {
  const [commentInputValue, setCommentInputValue] = useState<string>("");
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { mutate: postComment } = useMutation(
    ["addComments", orderId],
    () => postOrderComment(orderId, commentInputValue),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["comments", orderId]);
        setCommentInputValue("");
        toast.success("comment successfully published");
      },
    }
  );

  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          <TextField
            onChange={(e) => setCommentInputValue(e.currentTarget.value)}
            value={commentInputValue}
            multiline
            fullWidth
            sx={{ bgcolor: theme.comments.inputBackground }}
            placeholder="Add new comment..."
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={() => postComment()} disabled={!commentInputValue}>
            <Send />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default CommentInput;
