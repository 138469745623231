import React from "react";
import { GridToolbarFilterButton, GridToolbarContainer } from "@mui/x-data-grid-pro";
import SearchInput from "../../../components/SearchInput";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  setRangeDateValue,
  resetState,
  setDebouncedSearchValue,
  setReplaceSearchValueByDebounced,
} from "../../../redux/slices/ordersList";
import { Button, Stack, TextField } from "@mui/material";
import { DateRangePicker, LoadingButton } from "@mui/lab";
import moment from "moment";
import styled from "styled-components";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { useState } from "react";
import { useDebounce } from "react-use";
import { useEffect } from "react";
import { Add, Clear } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isOrderActionAuthorized } from "../../../utils/auth";
import { OrdersActions } from "../../../types/auth";

const GridToolbar = styled(GridToolbarContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
interface CustomToolbarProps {
  refetch: () => void;
  isFetching: boolean;
}

function CustomToolbar({ refetch, isFetching }: CustomToolbarProps) {
  const dispatch = useAppDispatch();
  const { dateRange, replaceSearchValueByDebounced, debouncedSearchValue } = useAppSelector(
    (state) => state.ordersList
  );
  const [searchValue, setSearchValue] = useState(debouncedSearchValue);
  const navigate = useNavigate();

  useEffect(() => {
    if (replaceSearchValueByDebounced) {
      setSearchValue(debouncedSearchValue);
      dispatch(setReplaceSearchValueByDebounced(false));
    }
  }, [replaceSearchValueByDebounced, debouncedSearchValue, dispatch]);

  const [,] = useDebounce(
    () => {
      dispatch(setDebouncedSearchValue(searchValue));
    },
    500,
    [searchValue]
  );

  const handleDateRangeChange = (value: DateRange<Date>) => {
    // reformat date to avoid store non-serialize value in state
    const startDate = value[0] ? moment(value[0]).format() : null;
    const endDate = value[1] ? moment(value[1]).format() : null;
    dispatch(setRangeDateValue([startDate, endDate]));
  };

  const handleReset = () => {
    dispatch(resetState());
    setSearchValue("");
  };

  return (
    <GridToolbar>
      <Stack direction="row" flexWrap="wrap" alignItems="center" spacing={1}>
        <GridToolbarFilterButton />
        <SearchInput
          onChange={(e) => {
            setSearchValue(e.currentTarget.value);
          }}
          resetValue={() => setSearchValue("")}
          value={searchValue}
        />
        <DateRangePicker
          value={dateRange}
          desktopModeMediaQuery="@media (min-width: 720px)"
          clearable
          inputFormat="dd/MM/yyyy"
          onChange={handleDateRangeChange}
          startText="Start (created)"
          endText="End (created)"
          renderInput={(startProps, endProps) => (
            <Stack direction="row" spacing={2}>
              <TextField size="small" style={{ width: 100 }} {...startProps} />
              <TextField size="small" style={{ width: 100 }} {...endProps} />
            </Stack>
          )}
        />
        <Button
          variant="text"
          disableRipple={false}
          size="small"
          onClick={handleReset}
          startIcon={<Clear />}
          disabled={false}
        >
          Reset all filters
        </Button>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 1, mx: 2 }}>
        <LoadingButton loading={isFetching} onClick={refetch} variant="contained">
          Refresh
        </LoadingButton>
        {isOrderActionAuthorized(OrdersActions.Create) && (
          <Button
            variant="outlined"
            color="success"
            startIcon={<Add />}
            onClick={() => navigate("/orders/create")}
          >
            Create Order
          </Button>
        )}
      </Stack>
    </GridToolbar>
  );
}

export default CustomToolbar;
