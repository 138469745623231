import React from "react";
import { Typography } from "@mui/material";

import { Platform } from "../../types/platforms";

interface CellPropsType {
  value: number;
  platforms: Array<Platform>;
}

function CustomCellPlatform({ value, platforms }: CellPropsType) {
  const label = platforms?.find((p: Platform) => p.id === value)?.label;

  return <Typography>{label}</Typography>;
}

export default CustomCellPlatform;
