import { Stack, Typography } from "@mui/material";
import { AirplanemodeInactive, FlightLand } from "@mui/icons-material";

interface CellPropsType {
  value: string | null;
}

function CustomCellArrivalFlight({ value }: CellPropsType) {
  return (
    <Stack flexDirection="row" alignItems="center">
      {value ? (
        <>
          <FlightLand fontSize="small" />
          <Typography fontWeight="bold" m={1}>
            {value}
          </Typography>
        </>
      ) : (
        <AirplanemodeInactive color="disabled" fontSize="small" />
      )}
    </Stack>
  );
}

export default CustomCellArrivalFlight;
