import Keycloak from "keycloak-js";
import { clientId, url, realm } from "./config";

const keycloak = Keycloak({
  realm: realm,
  url: url,
  clientId: clientId,
});

export default keycloak;
