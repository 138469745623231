import { Helmet } from "react-helmet-async";
import { Grid, Alert, useMediaQuery } from "@mui/material";
import { useSites } from "../../contexts/SiteContext";
import HeaderDatePicker from "./DatePicker";
import DesktopMovementsList from "./MovementsList/Desktop";
import Loader from "../../components/Loader";
import { useTheme } from "@mui/system";
import MobileMovementList from "./MovementsList/Mobile";
import moment from "moment";
import toast from "react-hot-toast";
import { useInfiniteQuery } from "react-query";
import { getDailyMovements } from "../../api/operations";
import { useAppSelector } from "../../hooks/redux";
import { selectDailyMovementsState } from "../../redux/slices/dailyMovements";
import { MovementQueryParams, Movement } from "../../types/movements";

const DailyMovementsList = () => {
  const { currentSite, isLoading: isSitesLoading, meetingPoints } = useSites();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { date: selectedDate, rowLimit } = useAppSelector(selectDailyMovementsState);

  function getParams(): MovementQueryParams {
    return {
      date: moment(selectedDate).format("YYYY-MM-DD"),
      site: currentSite?.id as number,
      row_limit: rowLimit,
    };
  }

  const params = getParams();

  const {
    isLoading: isMovementsLoading,
    data: movementsData,
    isFetching: isMovementsFetching,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ["dailyMovement", params],
    ({ pageParam }) => getDailyMovements(params, pageParam, meetingPoints),
    {
      enabled: !!currentSite && !isSitesLoading,
      getNextPageParam: (lastPage, pages) => {
        const lastPageIsFull = lastPage.length === rowLimit;
        return lastPageIsFull ? pages.length : undefined;
      },
      onError: () => {
        toast.error(
          `an error occurred while retrieving movements of ${moment(selectedDate).format(
            "DD-MM-YYYY"
          )}`
        );
      },
    }
  );

  const movementsList = movementsData?.pages[0]
    ? ([] as Movement[]).concat(...movementsData.pages)
    : [];

  if (isSitesLoading) {
    return <Loader />;
  } else if (!currentSite) {
    return (
      <Alert severity="warning">You have to select a site to display the daily movements</Alert>
    );
  }

  return (
    <>
      <Helmet title="Daily Movements" />
      <Grid
        container
        sx={{ height: "100%", flexDirection: "column", flexWrap: "nowrap" }}
        spacing={isMobileScreen ? 1 : 4}
      >
        <Grid item>
          <HeaderDatePicker displayShortCutButton={!isMobileScreen} />
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          {isMobileScreen ? (
            <MobileMovementList
              isLoading={isMovementsLoading}
              movementsData={movementsList}
              fetchNextPage={fetchNextPage}
              hasNextPage={!!hasNextPage}
            />
          ) : (
            <DesktopMovementsList
              movementsData={movementsList}
              isLoading={isMovementsLoading}
              isFetching={isMovementsFetching}
              fetchNextPage={fetchNextPage}
              refetch={refetch}
              hasNextPage={!!hasNextPage}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default DailyMovementsList;
