import {
  Add,
  Block,
  Close,
  Done,
  HourglassEmpty,
  ModeEdit,
  SvgIconComponent,
} from "@mui/icons-material";
import { Timeline, TimelineContent } from "@mui/lab";
import { Typography } from "@mui/material";
import { Order, OrderHistory, OrderStatus } from "../../../../../types/orders";
import { Palette } from "../../../../../types/themes";
import { dateTimeFormat } from "../../../../../utils/date";
import HistoryItem from "./Item";
import HistoryItemContent from "./Item/Content";

interface HistoryTimelineProps {
  orderData: Order;
  historyData: OrderHistory[] | undefined;
}

const HistoryTimeline = ({ orderData, historyData }: HistoryTimelineProps) => {
  function getStatusStyle(newStatus: OrderStatus | undefined): {
    color: Palette;
    Icon: SvgIconComponent;
  } {
    switch (newStatus) {
      case OrderStatus.Accepted:
        return { color: Palette.Success, Icon: Done };
      case OrderStatus.Cancelled:
        return { color: Palette.Error, Icon: Close };
      case OrderStatus.Failed:
        return { color: Palette.Error, Icon: Block };
      case OrderStatus.Pending:
        return { color: Palette.Warning, Icon: HourglassEmpty };
      default:
        return { color: Palette.Grey, Icon: ModeEdit };
    }
  }

  return (
    <Timeline>
      {historyData?.map((orderHistory, i) => {
        const newStatus = orderHistory.fieldsChanged.status?.to;
        const { color, Icon } = getStatusStyle(newStatus);

        return (
          <HistoryItem
            key={i}
            Icon={Icon}
            dotColor={color}
            date={orderHistory.date}
            author={orderHistory.author}
            hideFirstConnector={i === 0}
          >
            <HistoryItemContent
              historyData={orderHistory}
              orderId={orderData.id}
              statusColor={color}
            />
          </HistoryItem>
        );
      })}
      <HistoryItem
        Icon={Add}
        dotColor={Palette.Grey}
        hideLastConnector
        date={orderData.createdAt.format(dateTimeFormat)}
      >
        <TimelineContent sx={{ display: "flex", alignItems: "center", flexGrow: 4 }}>
          <Typography fontWeight={600}>Order Created</Typography>
        </TimelineContent>
      </HistoryItem>
    </Timeline>
  );
};

export default HistoryTimeline;
