import { ChangeEvent } from "react";
import { DatePicker } from "@mui/lab";
import { Divider, Grid, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import moment from "moment";
import { useQuery } from "react-query";
import {
  CreateOrderFormikValues,
  OrderFormikValues,
  UpdateOrderFormikValues,
} from "../../../types/orders";
import { Slot, SlotType } from "../../../types/sites";
import { getSiteDailySlots } from "../../../api/sites";
import ArrivalFlightInput from "./Inputs/ArrivalFlightInput";
import toast from "react-hot-toast";
import SiteInput from "./Inputs/SiteInput";
import MeetingPointInput from "./Inputs/MeetingPointInput";
import PassengersInput from "./Inputs/PassengersInput";
import SlotInput from "./Inputs/SlotInput";

interface OperationFormProps {
  isEditable: boolean;
  isRestrictedFieldsEditable: boolean;
  setSiteStartSlots: (slots: Slot[]) => void;
  setSiteEndSlots: (slots: Slot[]) => void;
}

const OperationForm = (props: OperationFormProps) => {
  const { isEditable, isRestrictedFieldsEditable, setSiteStartSlots, setSiteEndSlots } = props;
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    setValues,
    initialValues,
  } = useFormikContext<UpdateOrderFormikValues | CreateOrderFormikValues>();

  const { data: siteStartSlots } = useQuery(
    ["startSlots", { siteId: values.siteId, date: values.selectedStartDay }],
    () => getSiteDailySlots(values.siteId, values.selectedStartDay, SlotType.Departure),
    {
      onSuccess: (data) => setSiteStartSlots(data),
      onError: () => {
        toast.error("an error occurred while retrieving IN slots ");
      },
      enabled:
        !!values.selectedStartDay &&
        moment(values.selectedStartDay).isSameOrAfter(moment().startOf("day")) &&
        !!values.siteId,
    }
  );

  const { data: siteEndSlots } = useQuery(
    ["endSlots", { siteId: values.siteId, date: values.selectedEndDay }],
    () => getSiteDailySlots(values.siteId, values.selectedEndDay, SlotType.Arrival),
    {
      onSuccess: (data) => setSiteEndSlots(data),
      onError: () => {
        toast.error("an error occurred while retrieving OUT slots ");
      },
      enabled:
        !!values.selectedEndDay &&
        moment(values.selectedEndDay).isSameOrAfter(moment().startOf("day")) &&
        !!values.siteId,
    }
  );

  const dateFormat = "YYYY-MM-DD";

  return (
    <Stack>
      <Typography sx={{ fontSize: 16, fontWeight: "bold", p: 3, textAlign: "center" }}>
        Booking
      </Typography>
      <Divider variant="middle" />
      <Grid container spacing={5} p={5}>
        <Grid item xs={12}>
          <SiteInput
            setFieldValue={setFieldValue}
            value={values.siteId}
            initialValue={initialValues.siteId}
            errorMessage={touched.siteId ? errors.siteId : undefined}
            isEditable={isRestrictedFieldsEditable}
          />
        </Grid>
        <Grid item xs={6}>
          <MeetingPointInput
            inputLabel="Departure Meeting Point"
            inputName={OrderFormikValues.departureMeetingPointId}
            isEditable={isRestrictedFieldsEditable}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            selectedSiteId={values.siteId}
            value={values.departureMeetingPointId}
            errorMessage={
              touched.departureMeetingPointId ? errors.departureMeetingPointId : undefined
            }
          />
        </Grid>
        <Grid item xs={6}>
          <MeetingPointInput
            inputLabel="Arrival Meeting Point"
            inputName={OrderFormikValues.arrivalMeetingPointId}
            isEditable={isRestrictedFieldsEditable}
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            selectedSiteId={values.siteId}
            value={values.arrivalMeetingPointId}
            errorMessage={touched.arrivalMeetingPointId ? errors.arrivalMeetingPointId : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="IN (Date)"
            readOnly={!isRestrictedFieldsEditable}
            onChange={(value) => {
              setValues({
                ...values,
                selectedStartDay: moment(value).format(dateFormat),
                startDate: "",
              });
              setFieldTouched(OrderFormikValues.selectedStartDay, true, false);
            }}
            value={values.selectedStartDay || null}
            InputProps={{ name: OrderFormikValues.selectedStartDay }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={touched.selectedStartDay && !!errors.selectedStartDay}
                helperText={
                  touched.selectedStartDay && !!errors.selectedStartDay
                    ? errors.selectedStartDay
                    : ""
                }
                fullWidth
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <SlotInput
            initialValue={initialValues.startDate}
            value={values.startDate}
            slots={siteStartSlots}
            onChange={handleChange}
            onBlur={handleBlur}
            initialDay={initialValues.selectedStartDay}
            selectedDay={values.selectedStartDay}
            inputLabel="IN (hour)"
            inputName={OrderFormikValues.startDate}
            isEditable={isRestrictedFieldsEditable}
            errorMessage={touched.startDate ? errors.startDate : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            label="OUT (Date)"
            readOnly={!isRestrictedFieldsEditable}
            onChange={(value) => {
              setValues({
                ...values,
                selectedEndDay: moment(value).format(dateFormat),
                endDate: "",
              });
              setFieldTouched(OrderFormikValues.selectedEndDay, true, false);
            }}
            value={values.selectedEndDay || null}
            InputProps={{ name: OrderFormikValues.selectedEndDay }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={touched.selectedEndDay && !!errors.selectedEndDay}
                helperText={
                  touched.selectedEndDay && !!errors.selectedEndDay ? errors.selectedEndDay : ""
                }
                fullWidth
                variant="standard"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <SlotInput
            initialValue={initialValues.endDate}
            value={values.endDate}
            slots={siteEndSlots}
            onChange={handleChange}
            onBlur={handleBlur}
            initialDay={initialValues.selectedEndDay}
            selectedDay={values.selectedEndDay}
            inputLabel="OUT (hour)"
            inputName={OrderFormikValues.endDate}
            isEditable={isRestrictedFieldsEditable}
            errorMessage={touched.endDate ? errors.endDate : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <PassengersInput
            value={values.passengers}
            currentPassengers={initialValues.passengers || 0}
            onChange={handleChange}
            handleBlur={handleBlur}
            isEditable={isEditable}
            errorMessage={touched.passengers ? errors.passengers : undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <ArrivalFlightInput
            readOnly={!isEditable}
            value={values.arrivalFlightNumber}
            endDate={values.endDate}
            error={touched.arrivalFlightNumber && !!errors.arrivalFlightNumber}
            helperText={
              touched.arrivalFlightNumber && !!errors.arrivalFlightNumber
                ? errors.arrivalFlightNumber
                : ""
            }
            handleBlur={handleBlur}
            handleChange={(e: ChangeEvent) => {
              if ((e.currentTarget as HTMLInputElement).value === "") {
                setFieldValue(OrderFormikValues.arrivalFlightNumber, null);
              } else {
                handleChange(e);
              }
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default OperationForm;
