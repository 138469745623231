import { FocusEvent } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { labelLanguage } from "../../../../config";
import { useSites } from "../../../../contexts/SiteContext";

interface MeetingPointInputProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: number | undefined;
  selectedSiteId: number | undefined;
  isEditable: boolean;
  inputLabel: string;
  inputName: string;
  errorMessage: string | undefined;
}

const MeetingPointInput = ({
  setFieldValue,
  handleBlur,
  value,
  selectedSiteId,
  isEditable,
  inputLabel,
  inputName,
  errorMessage,
}: MeetingPointInputProps) => {
  const { sites } = useSites();

  const generateMeetingPointsOptions = () => {
    const selectedSiteData = sites.find((site) => site.id === selectedSiteId);
    const meetingPoints = selectedSiteData?.meetingPoints;
    const sortedMeetingPoints = meetingPoints?.sort((a, b) =>
      a.name[labelLanguage].localeCompare(b.name[labelLanguage])
    );

    return sortedMeetingPoints?.map(({ id, name }) => (
      <MenuItem key={id} value={id}>
        {name[labelLanguage]}
      </MenuItem>
    ));
  };

  return (
    <FormControl variant="standard" fullWidth error={!!errorMessage}>
      <InputLabel id={inputName}>{inputLabel}</InputLabel>
      <Select
        readOnly={!isEditable}
        inputProps={{
          name: inputName,
          id: inputName,
        }}
        onChange={(e) => setFieldValue(e.target.name, parseInt(e.target.value))}
        onBlur={handleBlur}
        value={value ? `${value}` : ""}
      >
        {generateMeetingPointsOptions()}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default MeetingPointInput;
