import { TimelineContent } from "@mui/lab";
import { Typography, Stack, Divider } from "@mui/material";
import { ArrowRightCircle } from "react-feather";
import { OrderHistory } from "../../../../../../types/orders";
import { Palette } from "../../../../../../types/themes";
import { capitalizeAndSplitCamelCase, getDiffWithSymbol } from "../../../../../../utils/text";

const HistoryItemContent = ({
  historyData,
  statusColor,
}: {
  historyData: OrderHistory;
  orderId: string;
  statusColor: Palette;
}) => {
  const changedStatus = historyData.fieldsChanged.status?.to;

  const {
    totalPriceInclTaxes: price,
    totalPriceExclTaxes,
    totalDays,
    ...fieldsChangedToDisplay
  } = historyData.fieldsChanged;

  return (
    <TimelineContent sx={{ display: "flex", alignItems: "center", flexGrow: 4 }}>
      {changedStatus ? (
        <Typography sx={{ fontWeight: 600 }} color={statusColor}>
          {capitalizeAndSplitCamelCase(changedStatus)}
        </Typography>
      ) : (
        <Stack>
          {Object.entries(fieldsChangedToDisplay).map((fieldChanged, i) => {
            const fieldName = fieldChanged[0];
            const { from: initialValue, to: changedValue } = fieldChanged[1];
            return (
              <Stack key={i} alignItems="center" direction="row" spacing={3}>
                <Typography sx={{ fontWeight: 600 }}>
                  {capitalizeAndSplitCamelCase(fieldName)}:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "error.main",
                    whiteSpace: "nowrap",
                  }}
                >
                  {initialValue}
                </Typography>
                <ArrowRightCircle size={14} />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "success.main",
                    whiteSpace: "nowrap",
                  }}
                >
                  {changedValue}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      )}
      {(price || totalDays) && (
        <>
          <Divider orientation="vertical" sx={{ height: "75%", mx: 5 }} />
          <Stack>
            {price && <Typography>{getDiffWithSymbol(price.from, price.to)} €</Typography>}
            {totalDays && (
              <Typography>{getDiffWithSymbol(totalDays.from, totalDays.to)} day(s)</Typography>
            )}
          </Stack>
        </>
      )}
    </TimelineContent>
  );
};

export default HistoryItemContent;
