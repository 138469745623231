import React, { useState } from "react";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { Box, Grid, TextField } from "@mui/material";
import { DateRangePicker, LoadingButton } from "@mui/lab";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";

import { setParkingCapacity } from "../../../api/sites";
import { useSites } from "../../../contexts/SiteContext";
import { addDays } from "date-fns";

interface ParkingCapacityValues {
  capacity: number;
  start: string;
  end?: string;
  siteId: number;
}

const ParkingCapacity = () => {
  const [capacity, setCapacity] = useState<number>(100);
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);

  const { currentSite } = useSites();

  const handleDateRangeChange = (newValue: any) => setDateRange(newValue);

  const { mutate, isLoading } = useMutation(
    "setParkingCapacity",
    (changedValues: ParkingCapacityValues) => {
      // Adding 1 day to end date to include it in the new capacity
      const endDate = changedValues.end
        ? addDays(new Date(changedValues.end), 1).toISOString()
        : undefined;
      return setParkingCapacity(
        changedValues.siteId,
        changedValues.capacity,
        changedValues.start,
        endDate
      );
    },
    {
      onMutate: () => {
        toast.loading(`Parking capacity update in progress...`, { id: "setParkingCapacity" });
      },
      onSuccess: () => {
        toast.success(`Parking capacity successfully updated`, { id: "setParkingCapacity" });
      },
      onError: () => {
        toast.error(`Something went wrong during parking capacity update`, {
          id: "setParkingCapacity",
        });
      },
    }
  );

  const handleSubmit = () => {
    if (dateRange[0] && currentSite?.id) {
      mutate({
        siteId: currentSite.id,
        start: dateRange[0].toISOString(),
        end: dateRange[1] ? dateRange[1].toISOString() : undefined,
        capacity: capacity,
      });
    }
  };

  return (
    <Grid container spacing={5} p={5}>
      <Grid item xs={12} md={6}>
        <DateRangePicker
          value={dateRange}
          onChange={handleDateRangeChange}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </React.Fragment>
          )}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          id="ParkingCapacity"
          label="Capacity"
          type="number"
          value={capacity}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event?.target?.value && !isNaN(Number(event.target.value))) {
              setCapacity(Number(event.target.value));
            }
          }}
          inputProps={{
            min: 0,
            step: 10,
          }}
        />
      </Grid>
      <Grid item xs={12} md={2} alignContent="center">
        <LoadingButton onClick={() => handleSubmit()} variant="contained" loading={isLoading}>
          Save
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default ParkingCapacity;
