import { useTheme } from "@mui/material";
import { Toaster } from "react-hot-toast";

const ToasterAlert = () => {
  const theme = useTheme();

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        error: {
          duration: 8000,
        },
        success: {
          duration: 2500,
        },
        loading: {
          duration: 300000,
        },
        style: {
          fontWeight: "bold",
          backgroundColor: theme.alert.background,
          color: theme.alert.color,
        },
      }}
    />
  );
};

export default ToasterAlert;
