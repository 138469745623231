import BTServer from "./axiosInterceptors/BTServer";

export const getArrivalFlightInfo = (
  arrivalFlightNumber: string | undefined,
  endDate: string | undefined
) =>
  typeof arrivalFlightNumber === "undefined" || typeof endDate === "undefined"
    ? Promise.reject(new Error("Invalid parameters"))
    : BTServer.get("/flights/check", {
        params: {
          flight_number: arrivalFlightNumber,
          end_date: endDate,
        },
      }).then((res) => res.data);
