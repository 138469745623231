import React from "react";
import styled from "styled-components";
import LogoAnimated from "../assets/img/logo-animated.svg";

const Container = styled.div<{ fullScreen: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  position: ${(props) => (props.fullScreen ? "absolute" : "unset")};
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img<{ fullScreen: boolean }>`
  height: ${(props) => (props.fullScreen ? "100px" : "100%")};
  max-height: 100px;
`;

export default function Loader({ fullScreen = false }) {
  return (
    <Container fullScreen={fullScreen}>
      <Logo fullScreen={fullScreen} alt="Loader" src={LogoAnimated} />
    </Container>
  );
}
