export const realm: string = process.env.REACT_APP_KEYCLOAK_REALM as string;
export const url: string = process.env.REACT_APP_KEYCLOAK_URL as string;
export const clientId: string = process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string;

export const BTSBaseUrl: string = process.env.REACT_APP_BT_SERVER_URL as string;

export const licenseKeyMuiProX: string = process.env.REACT_APP_MUI_LICENSE_KEY as string;

export const mainLanguage = "en";
export const labelLanguage = "fr";
