import { Container, Divider, Typography } from "@mui/material";
import styled from "styled-components";

const CounterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: -5px;
  height: 20px;
  width: 20px;
  margin: 0px;
  background-color: ${(props) => props.theme.comments.background};
  border-radius: 20px;
`;

const CommentsHeader = ({ commentsLength }: { commentsLength: number | undefined }) => {
  return (
    <>
      <Container disableGutters sx={{ position: "relative", width: "fit-content", m: 0 }}>
        <Typography sx={{ fontSize: 16, fontWeight: "bold" }} p={4}>
          Comments
        </Typography>
        {commentsLength !== undefined && (
          <CounterContainer disableGutters>
            <Typography
              sx={{
                fontSize: 12,
                textAlign: "center",
                fontWeight: "bold",
                color: "white",
              }}
            >
              {commentsLength}
            </Typography>
          </CounterContainer>
        )}
      </Container>
      <Divider />
    </>
  );
};

export default CommentsHeader;
