import { Navigate, Outlet } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import Page403 from "./pages/auth/Page403";
import Page404 from "./pages/auth/Page404";
import DailyMovementsList from "./pages/dailyMovements";
import OrderList from "./pages/orders/ordersList";
import Order from "./pages/orders/orderDetails";
import OrderCreation from "./pages/orders/ordersCreation";
import Site from "./pages/sites";
import { getRole } from "./utils/auth";
import { Roles } from "./types/auth";

const ProtectedRoute = ({
  children,
  authorizedRoles = [],
}: {
  children: JSX.Element;
  authorizedRoles: string[];
}): JSX.Element | null => {
  const role = getRole();
  if (role && authorizedRoles.includes(role)) {
    return children ? children : <Outlet />;
  }
  return <Page403 message="You're not allowed to view this page" signOut={false} />;
};

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "", element: <Navigate to="/orders" /> },
      { path: "orders", element: <OrderList /> },
      { path: "orders/create", element: <OrderCreation /> },
      { path: "orders/:id", element: <Order /> },
      { path: "movements", element: <DailyMovementsList /> },
      {
        path: "sites",
        element: <ProtectedRoute authorizedRoles={[Roles.Admin]} children={<Site />} />,
      },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
