import InfiniteScroll from "react-infinite-scroll-component";
import { useCopyToClipboard } from "react-use";
import { Alert } from "@mui/material";
import Loader from "../../../../components/Loader";
import { Movement } from "../../../../types/movements";
import MovementCard from "./Card";

interface MovementListProps {
  movementsData: Movement[];
  isLoading: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
}

const MobileMovementList = ({
  movementsData,
  isLoading,
  fetchNextPage,
  hasNextPage,
}: MovementListProps) => {
  const [copyState, copyToClipboard] = useCopyToClipboard();

  return (
    <InfiniteScroll
      dataLength={movementsData.length}
      hasMore={hasNextPage}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 15,
        padding: 10,
      }}
      scrollableTarget="root"
      next={() => fetchNextPage()}
      loader={<Loader />}
    >
      {movementsData.map((movement) => (
        <MovementCard
          movement={movement}
          key={movement.id}
          copyState={copyState}
          copyToClipboard={copyToClipboard}
        />
      ))}
      {!isLoading && movementsData.length === 0 && (
        <Alert severity="info" sx={{ marginTop: 10 }}>
          No movement this day...
        </Alert>
      )}
    </InfiniteScroll>
  );
};
export default MobileMovementList;
