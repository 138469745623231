import { Helmet, HelmetProvider } from "react-helmet-async";
import { ThemeProvider as StyledThemeProvider } from "styled-components/macro";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import { StyledEngineProvider } from "@mui/styled-engine";
import useTheme from "./hooks/useTheme";
import createTheme from "./theme";
import { useKeycloak } from "@react-keycloak/web";

import "./i18n";
import Loader from "./components/Loader";
import { SitesProvider } from "./contexts/SiteContext";

import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { getRole } from "./utils/auth";
import Page403 from "./pages/auth/Page403";
import ToasterAlert from "./components/ToasterAlert";

function App() {
  const { keycloak } = useKeycloak();
  const appRoutes = useRoutes(routes);
  const { theme } = useTheme();

  const hasRole = !!getRole();

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Back office" defaultTitle="Back office" />
      <StylesProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <StyledThemeProvider theme={createTheme(theme)}>
                {keycloak.authenticated ? (
                  hasRole ? (
                    <SitesProvider>{appRoutes}</SitesProvider>
                  ) : (
                    <Page403 />
                  )
                ) : (
                  <Loader fullScreen />
                )}
                <ToasterAlert />
              </StyledThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );
}

export default App;
