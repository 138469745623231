import { Container, Typography } from "@mui/material";
import LuggageIcon from "@mui/icons-material/Luggage";
import { Moment } from "moment";
import { MovementType } from "../../../../../types/movements";

interface HeaderCardProps {
  isIn: boolean;
  movementDate: Moment;
  hasLuggage: boolean;
}

const HeaderCard = ({ isIn, movementDate, hasLuggage }: HeaderCardProps) => {
  return (
    <Container
      sx={{
        height: 30,
        bgcolor: isIn ? "green" : "red",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderRadius: "12px 12px 0 0",
      }}
    >
      <Typography color="white" fontWeight="bold" sx={{ position: "absolute", left: 10 }}>
        {isIn ? MovementType.IN : MovementType.OUT}
      </Typography>
      <Typography color="white" fontWeight="bold" fontSize={14}>
        {movementDate.format("HH:mm")}
      </Typography>
      {hasLuggage && (
        <Typography
          color="white"
          fontWeight="bold"
          fontSize={14}
          sx={{ position: "absolute", right: 10, bottom: 0 }}
        >
          <LuggageIcon />
        </Typography>
      )}
    </Container>
  );
};

export default HeaderCard;
