import { Card } from "@mui/material";
import { CopyToClipboardState } from "react-use/lib/useCopyToClipboard";
import { labelLanguage } from "../../../../../config";
import { Movement, MovementType } from "../../../../../types/movements";
import { OrderLuggage } from "../../../../../types/orders";
import { Palette } from "../../../../../types/themes";
import MovementCardActions from "./Actions";
import HeaderCard from "./Header";
import CardInfo from "./Info";

interface MovementCardProps {
  movement: Movement;
  copyState: CopyToClipboardState;
  copyToClipboard: (email: string) => void;
}

const MovementCard = ({ movement, copyState, copyToClipboard }: MovementCardProps) => {
  const isIn = movement.movementType === MovementType.IN;

  return (
    <Card
      sx={{
        bgcolor: isIn ? Palette.TransparencyGreen : Palette.TransparencyRed,
        width: "90%",
        maxWidth: 400,
        borderRadius: 4,
      }}
    >
      <HeaderCard
        isIn={isIn}
        movementDate={movement.movementDate}
        hasLuggage={movement.luggage === OrderLuggage.CheckedBag}
      />
      <CardInfo
        isIn={isIn}
        customerName={`${movement.lastName?.toUpperCase()} ${movement.firstName}`}
        passengers={movement.passengers}
        arrivalFlight={movement.arrivalFlightNumber}
        endDate={movement.endDate}
        vehicleModel={movement.vehicleModel}
        vehicleColor={movement.vehicleColor}
        meetingPointName={movement.meetingPoint?.name[labelLanguage]}
        parkName={movement.parkName}
        parkLocation={movement.parkLocation}
      />
      <MovementCardActions
        phoneNumber={movement.phone}
        email={movement.email}
        orderId={movement.orderId}
        copyState={copyState}
        copyToClipboard={copyToClipboard}
      />
    </Card>
  );
};
export default MovementCard;
