import React from "react";
import { Helmet } from "react-helmet-async";
import { useInfiniteQuery, useQuery } from "react-query";
import moment, { Moment } from "moment";
import {
  GridFilterModel,
  DataGridPro,
  GridColumns,
  getGridSingleSelectOperators,
  GridLinkOperator,
} from "@mui/x-data-grid-pro";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomToolbar from "./CustomToolbar";
import CustomCellId from "../../../components/customCell/Id";
import CustomCellSite from "../../../components/customCell/Site";
import CustomCellStatus from "../../../components/customCell/Status";
import CustomCellCancellationOption from "../../../components/customCell/CancellationOption";
import CustomCellPassengers from "../../../components/customCell/Passengers";
import CustomCellDateTime from "../../../components/customCell/DateTime";
import CustomCellEmail from "../../../components/customCell/Email";
import CustomCellPlatform from "../../../components/customCell/Platform";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { setActiveSort, selectOrdersListState, setFilter } from "../../../redux/slices/ordersList";
import { getOrders } from "../../../api/orders";
import { getPlatforms } from "../../../api/platforms";
import { useSites } from "../../../contexts/SiteContext";
import { Order, OrderFields, OrdersQueryParams, OrderStatus } from "../../../types/orders";
import { Palette } from "../../../types/themes";
import { hasSiteConstraint } from "../../../utils/auth";

const OrderList = () => {
  const { activeSort, debouncedSearchValue, pageRowLimit, dateRange, filter } =
    useAppSelector(selectOrdersListState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { sites, authorizedSites } = useSites();

  const { data: platforms } = useQuery("platforms", getPlatforms, {
    onError: () => {
      toast.error("An error occurred while fetching platforms data");
    },
  });

  const siteFilterValue =
    hasSiteConstraint() && !filter.site ? authorizedSites[0]?.label.fr : filter.site;

  const getParams = () => {
    const { sort, field } = activeSort?.[0];
    const params: OrdersQueryParams = {
      search: debouncedSearchValue ? debouncedSearchValue : undefined,
      row_limit: pageRowLimit,
      sort_by: field,
      sort_type: sort ? sort.toUpperCase() : undefined,
      created_date_range_begin: dateRange[0]
        ? moment(dateRange[0]).format("YYYY-MM-DD")
        : undefined,
      created_date_range_end: dateRange[1] ? moment(dateRange[1]).format("YYYY-MM-DD") : undefined,
      site: siteFilterValue
        ? sites?.find((site) => site.label.fr === siteFilterValue)?.id
        : undefined,
      status: filter.status ? filter.status : undefined,
      platform_id: filter.platform
        ? platforms?.find((p) => p.label === filter.platform)?.id
        : undefined,
    };

    return params;
  };

  const params = getParams();

  const {
    isLoading,
    data: ordersData,
    isFetching,
    refetch,
    fetchNextPage,
  } = useInfiniteQuery(["orders", params], ({ pageParam }) => getOrders(params, pageParam), {
    getNextPageParam: (lastPage, pages) => {
      const lastPageIsFull = lastPage.length === pageRowLimit;
      return lastPageIsFull ? pages.length : undefined;
    },
    onError: () => {
      toast.error("an error occurred while retrieving orders");
    },
  });

  const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
    ["is"].includes(value)
  );

  const filterSiteValueOptions = authorizedSites.map((site) => site.label.fr);
  const filterPlatformOptions = platforms?.map((platform) => platform.label) || [];

  const column: GridColumns = [
    {
      field: OrderFields.number,
      headerName: "ID",
      width: 110,
      renderCell: (params) => <CustomCellId value={params.value} orderId={params.id} />,
      filterable: false,
    },
    {
      field: OrderFields.startDate,
      headerName: "IN",
      width: 125,
      filterable: false,
      renderCell: ({ value }: { value: Moment }) => <CustomCellDateTime value={value} />,
    },
    {
      field: OrderFields.endDate,
      headerName: "OUT",
      width: 125,
      filterable: false,
      renderCell: ({ value }: { value: Moment }) => <CustomCellDateTime value={value} />,
    },
    {
      field: OrderFields.siteId,
      headerName: "Site",
      width: 140,
      renderCell: (params) => <CustomCellSite value={params.value} sites={sites} />,
      type: "singleSelect",
      filterOperators,
      valueOptions: filterSiteValueOptions,
    },
    {
      field: OrderFields.passengers,
      headerName: "Passengers",
      align: "center",
      width: 80,
      renderCell: (params) => <CustomCellPassengers value={params.value} />,
      filterable: false,
    },
    {
      field: OrderFields.email,
      headerName: "Email",
      width: 200,
      renderCell: (params) => <CustomCellEmail value={params.value} />,
      filterable: false,
    },
    {
      field: OrderFields.totalPrice,
      headerName: "Total",
      width: 80,
      renderCell: (params) => `${params.value.toFixed(2)} €`,
      filterable: false,
    },
    {
      field: OrderFields.status,
      headerName: "Status",
      width: 100,
      renderCell: (params) => <CustomCellStatus value={params.value} />,
      type: "singleSelect",
      filterOperators,
      valueOptions: Object.values(OrderStatus),
    },
    {
      field: OrderFields.createdAt,
      headerName: "Created",
      width: 125,
      filterable: false,
      renderCell: ({ value }: { value: Moment }) => <CustomCellDateTime value={value} />,
    },
    {
      field: OrderFields.cancellationOption,
      headerName: "Cancellation Option",
      align: "center",
      width: 100,
      filterable: false,
      sortable: false,
      renderCell: (params) => <CustomCellCancellationOption value={params.value} />,
    },
    {
      field: OrderFields.externalOrderNumber,
      headerName: "External Number",
      width: 100,
      filterable: false,
      sortable: false,
    },
    {
      field: OrderFields.platformId,
      headerName: "Platform",
      width: 120,
      renderCell: (params) => (
        <CustomCellPlatform value={params.value} platforms={platforms || []} />
      ),
      filterOperators,
      valueOptions: filterPlatformOptions,
      sortable: false,
    },
  ];

  const handleFilterChange = (data: GridFilterModel) => {
    const filterStatusValue = data.items?.[0].value as OrderStatus;
    const filterSiteValue = data.items?.[1].value;
    const isSiteFilterRequired = hasSiteConstraint();
    const filterPlatformValue = data.items?.[2].value;
    dispatch(
      setFilter({
        status: filterStatusValue,
        site:
          isSiteFilterRequired && !filterSiteValue ? filterSiteValueOptions[0] : filterSiteValue,
        platform: filterPlatformValue,
      })
    );
  };

  const ordersList = ordersData?.pages[0]
    ? ([] as Order[]).concat(...ordersData.pages)
    : ([] as Order[]);

  const filterModel: GridFilterModel = {
    items: [
      {
        columnField: "status",
        operatorValue: "is",
        id: 1,
        value: filter.status,
      },
      {
        columnField: "siteId",
        operatorValue: "is",
        id: 2,
        value: siteFilterValue,
      },
      {
        columnField: "platformId",
        operatorValue: "is",
        id: 3,
        value: filter.platform,
      },
    ],
    linkOperator: GridLinkOperator.And,
  };

  return (
    <React.Fragment>
      <Helmet title="Orders" />
      <DataGridPro
        sx={{
          bgcolor: Palette.PaperBackground,
          minHeight: 600,
        }}
        hideFooter
        rows={ordersList}
        columns={column}
        onRowDoubleClick={(order) => {
          navigate(`/orders/${order.id}`);
        }}
        disableColumnMenu
        disableSelectionOnClick
        loading={isLoading || isFetching}
        filterMode="server"
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        sortingMode="server"
        onSortModelChange={(value) => dispatch(setActiveSort(value))}
        sortModel={activeSort}
        sortingOrder={["desc", "asc"]}
        onRowsScrollEnd={() => fetchNextPage()}
        components={{
          Toolbar: CustomToolbar,
        }}
        componentsProps={{
          toolbar: {
            refetch,
            isLoading,
            isFetching,
          },
          filterPanel: {
            linkOperators: ["and"],
            // Necessary to hide the "add filter" and the delete button
            // We don't want the user to be able to add or delete an additional multicolumn filter as we currently don't support it on the api side
            sx: {
              "& .MuiDataGrid-panelFooter, & .MuiDataGrid-filterFormDeleteIcon, & .MuiDataGrid-filterFormLinkOperatorInput":
                {
                  display: "none",
                },
            },
          },
        }}
      />
    </React.Fragment>
  );
};

export default OrderList;
