export enum Roles {
  Admin = "admin",
  CosAgent = "cos-agent",
  SiteManager = "site-manager",
  Driver = "driver",
}

export enum ActionsTargets {
  Orders = "orders",
  Sites = "sites",
}

export enum OrdersActions {
  Read = "read",
  Edit = "edit",
  EditRestrictedFields = "editRestrictedFields",
  SendEmail = "sendEmail",
  PostComment = "postComment",
  Create = "create",
  Cancel = "cancel",
}

export enum SitesActions {
  ParkingCapacity = "parkingCapacity",
  ClosedSlots = "closedSlots",
  Edit = "edit",
}

export interface RolesAuthorizations {
  [Roles.CosAgent]: boolean;
  [Roles.SiteManager]: boolean;
  [Roles.Driver]: boolean;
}

export function defaultAuthorizations(): RolesAuthorizations {
  return {
    [Roles.CosAgent]: false,
    [Roles.SiteManager]: false,
    [Roles.Driver]: false,
  };
}

export interface AuthorizationsType {
  [ActionsTargets.Orders]: {
    [key in OrdersActions]: RolesAuthorizations;
  };
  [ActionsTargets.Sites]: {
    [key in SitesActions]: RolesAuthorizations;
  };
}
