import { Breadcrumbs, Button, Grid, Link, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { NavLink } from "react-router-dom";
import { cancelOrder, reopenCancelledOrder, resendEmailConfirmation } from "../../../api/orders";
import { Order, OrderStatus } from "../../../types/orders";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { isOrderActionAuthorized } from "../../../utils/auth";
import { OrdersActions } from "../../../types/auth";
import { Email } from "@mui/icons-material";
import { setDebouncedSearchValue } from "../../../redux/slices/ordersList";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/redux";

interface OrderDetailsHeaderProps {
  orderData: Order;
  refetch: () => void;
  isFetching: boolean;
  isEditable: boolean;
}

const OrderDetailsHeader = ({
  orderData,
  isFetching,
  refetch,
  isEditable,
}: OrderDetailsHeaderProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cancellationToastId = `cancellation_${orderData.id}`;
  const resendEmailToastId = `resendEmail_${orderData.id}`;
  const reopeningToastId = `reopening_${orderData.id}`;

  const { mutate: resendEmail, isLoading: isResendEmailLoading } = useMutation(
    "resendEmailConfirmation",
    () => resendEmailConfirmation(orderData.id),
    {
      onMutate: () => {
        toast.loading(`${orderData.number} email resend in progress`, {
          id: resendEmailToastId,
        });
      },
      onSuccess: () => {
        toast.success(`${orderData.number} email successfully sent`, {
          id: resendEmailToastId,
        });
      },
      onError: () => {
        toast.error(`Something went wrong during ${orderData.number} email sending`, {
          id: resendEmailToastId,
        });
      },
    }
  );

  const { mutate: triggerCancelOrder, isLoading: isCancellationLoading } = useMutation(
    "cancelOrder",
    () => cancelOrder(orderData.id),
    {
      onMutate: () => {
        toast.loading(`${orderData.number} cancellation in progress`, {
          id: cancellationToastId,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["order", orderData.id]);
        queryClient.invalidateQueries("orders");
        queryClient.invalidateQueries(["history", orderData.id]);
        toast.success(`${orderData.number} successfully cancelled`, {
          id: cancellationToastId,
        });
      },
      onError: () => {
        toast.error(`Something went wrong during ${orderData.number} cancellation`, {
          id: cancellationToastId,
        });
      },
    }
  );

  const { mutate: triggerReopenOrder, isLoading: isReopenOrderLoading } = useMutation(
    "reopenOrder",
    () => reopenCancelledOrder(orderData.id),
    {
      onMutate: () => {
        toast.loading(`${orderData.number} reopening in progress`, {
          id: reopeningToastId,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["order", orderData.id]);
        queryClient.invalidateQueries("orders");
        queryClient.invalidateQueries(["history", orderData.id]);
        toast.success(`${orderData.number} successfully reopened`, {
          id: reopeningToastId,
        });
      },
      onError: () => {
        toast.error(`Something went wrong during ${orderData.number} reopening`, {
          id: reopeningToastId,
        });
      },
    }
  );

  const handleSearchCustomerEmail = () => {
    dispatch(setDebouncedSearchValue(orderData.email));
    navigate("/orders");
  };

  const isLoading = isResendEmailLoading || isCancellationLoading || isReopenOrderLoading;

  const isPlatformOrder = !!orderData.platformId;

  const displayReopenButton =
    orderData.status === OrderStatus.Cancelled && isOrderActionAuthorized(OrdersActions.Create);

  const displayCancelButton =
    orderData.status === OrderStatus.Accepted && isOrderActionAuthorized(OrdersActions.Cancel);

  const displayResendEmailButton =
    !isPlatformOrder &&
    orderData.status === OrderStatus.Accepted &&
    isOrderActionAuthorized(OrdersActions.SendEmail);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState({
    email: false,
    cancel: false,
    reopen: false,
  });

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      <Grid item flexDirection="column">
        <Typography variant="h3" gutterBottom display="inline">
          Order {orderData?.number}
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb">
          <Typography>Customer</Typography>
          <Link component={NavLink} to="/orders">
            Orders
          </Link>
          <Typography>Order {orderData?.number}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={3}>
          <LoadingButton variant="contained" loading={isFetching} onClick={refetch}>
            Refresh
          </LoadingButton>
          <Button variant="contained" color="info" onClick={handleSearchCustomerEmail}>
            Customer Orders
          </Button>
          {displayResendEmailButton && (
            <>
              <Button
                variant="contained"
                color="info"
                onClick={() => setConfirmModalOpen({ ...isConfirmModalOpen, email: true })}
                disabled={isLoading}
                startIcon={<Email />}
              >
                Resend
              </Button>
              <ConfirmDialog
                title={`Resend the email confirmation for order ${orderData.number}`}
                open={isConfirmModalOpen.email}
                onConfirm={() => resendEmail()}
                onClose={() => setConfirmModalOpen({ ...isConfirmModalOpen, email: false })}
              />
            </>
          )}
          {displayCancelButton && (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={() => setConfirmModalOpen({ ...isConfirmModalOpen, cancel: true })}
                disabled={isLoading}
              >
                Cancel Order
              </Button>
              <ConfirmDialog
                title={`Cancellation of order ${orderData.number}`}
                open={isConfirmModalOpen.cancel}
                onConfirm={() => triggerCancelOrder()}
                onClose={() => setConfirmModalOpen({ ...isConfirmModalOpen, cancel: false })}
              />
            </>
          )}
          {displayReopenButton && (
            <>
              <Button
                variant="contained"
                color="success"
                onClick={() => setConfirmModalOpen({ ...isConfirmModalOpen, reopen: true })}
                disabled={isLoading}
              >
                Reopen Order
              </Button>
              <ConfirmDialog
                title={`Reopen the order ${orderData.number}`}
                open={isConfirmModalOpen.reopen}
                onConfirm={() => triggerReopenOrder()}
                onClose={() => setConfirmModalOpen({ ...isConfirmModalOpen, reopen: false })}
              />
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OrderDetailsHeader;
