import React from "react";
import {
  Autocomplete,
  Container,
  InputAdornment,
  TextField,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useSites } from "../contexts/SiteContext";
import { Site } from "../types/sites";
import { LocationOn } from "@mui/icons-material";

export default function SiteSelector() {
  const {
    authorizedSites,
    currentSite,
    setCurrentSite,
    isLoading: sitesDataIsLoading,
    isError,
  } = useSites();

  if (!authorizedSites || sitesDataIsLoading || !currentSite) {
    return (
      <Container sx={{ height: 30 }}>
        {isError ? (
          <Alert severity="error">an error occurred while retrieving sites</Alert>
        ) : (
          <CircularProgress />
        )}
      </Container>
    );
  }

  return (
    <Autocomplete
      id="globalSite"
      options={authorizedSites}
      fullWidth
      sx={{ maxWidth: "250px" }}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      )}
      getOptionLabel={(site: Site) => site.label.fr || site.id.toString()}
      onChange={(_, value) => {
        if (value) {
          setCurrentSite(value);
        }
      }}
      value={currentSite}
      autoComplete={true}
    />
  );
}
