import { Alert, Container, Divider, Paper, Typography } from "@mui/material";
import Loader from "../../../../components/Loader";
import { Order, OrderHistory } from "../../../../types/orders";
import HistoryTimeline from "./History";

interface OrderTimelineProps {
  orderData: Order;
  historyData: OrderHistory[] | undefined;
  isError: boolean;
  isLoading: boolean;
}

const OrderTimeline = ({ orderData, historyData, isError, isLoading }: OrderTimelineProps) => {
  return (
    <Paper>
      <Typography sx={{ fontSize: 16, fontWeight: "bold" }} p={4}>
        Timeline
      </Typography>
      <Divider />
      <Container sx={{ overflow: "auto" }} maxWidth={false}>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <Alert severity="error" sx={{ m: 2 }}>
            No history found for order {orderData.number}
          </Alert>
        ) : (
          <HistoryTimeline historyData={historyData} orderData={orderData} />
        )}
      </Container>
    </Paper>
  );
};

export default OrderTimeline;
