import merge from "deepmerge";
import { grey } from "@mui/material/colors";
import { VariantType } from "../types/themes";
import { THEMES } from "../types/themes";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#7ea8e5",
  400: "#6395e0",
  500: "#4782da",
  600: "#407ad6",
  700: "#376fd0",
  800: "#2f65cb",
  900: "#2052c2 ",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customBlue[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    background: {
      default: "#F7F9FC",
      paper: "#FFF",
    },
    green: "#4CAF50",
    red: "#C62828",
    transparency: {
      green: "rgba(65, 195, 65, 0.10)",
      red: "rgba(255, 0, 0, 0.10)",
      blue: "rgba(30, 136, 229, 0.12)",
    },
  },
  alert: {
    color: "#FFF",
    background: "#233044",
  },
  header: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#233044",
    header: {
      color: grey[200],
      background: "#233044",
      brand: {
        color: customBlue[500],
      },
    },
  },
  comments: {
    background: "#3b608b",
    inputBackground: "#f5f5f5",
  },
};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[600],
      contrastText: "#FFF",
    },
    background: {
      default: "#1B2635",
      paper: "#233044",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  alert: {
    color: "#233044",
    background: "#FFF",
  },
  header: {
    color: grey[300],
    background: "#1B2635",
  },
  comments: {
    inputBackground: "#2d3843",
  },
});

const variants: Array<VariantType> = [defaultVariant, darkVariant];

export default variants;
