import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { TextField, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

interface SearchInputType {
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  resetValue: MouseEventHandler<HTMLButtonElement>;
}

const SearchInput = ({ onChange, value, resetValue }: SearchInputType) => {
  return (
    <TextField
      style={{ margin: 4 }}
      value={value}
      onChange={onChange}
      variant="standard"
      placeholder="Search…"
      InputProps={{
        startAdornment: <SearchIcon fontSize="small" />,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: value ? "visible" : "hidden" }}
            onClick={resetValue}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchInput;
