import { labelLanguage } from "../config";
import {
  Order,
  OrderHistory,
  OrderQueryResponse,
  OrdersQueryParams,
  OrderUpdateValues,
  OrderComment,
  OrderHistoryQueryResponse,
  CreateOrderPlatformQuery,
  CreateOrderQuery,
} from "../types/orders";
import { MeetingPoint } from "../types/sites";
import {
  convertZonedDateTimeToMomentDate,
  dateTimeFormat,
  formatFromZonedDateTime,
} from "../utils/date";
import BTServer from "./axiosInterceptors/BTServer";

const processOrderResponse = (order: OrderQueryResponse) => {
  return {
    ...order,
    startDate: convertZonedDateTimeToMomentDate(order.startDate),
    endDate: convertZonedDateTimeToMomentDate(order.endDate),
    createdAt: convertZonedDateTimeToMomentDate(order.createdAt),
  };
};

const getMeetingPointLabelById = (
  meetingPoints: MeetingPoint[],
  researchedMeetingPointId: number
) => {
  return meetingPoints.find((meetingPoint) => meetingPoint.id === researchedMeetingPointId)?.name[
    labelLanguage
  ];
};

const processHistoryResponse = (
  history: OrderHistoryQueryResponse,
  meetingPoints: MeetingPoint[]
) => {
  const { date, fieldsChanged } = history;
  const { departureMeetingPointId, arrivalMeetingPointId, ...filteredFieldsChanged } =
    fieldsChanged;

  return {
    ...history,
    date: formatFromZonedDateTime(date, dateTimeFormat),
    fieldsChanged: {
      ...filteredFieldsChanged,
      ...(fieldsChanged.startDate && {
        startDate: {
          from: formatFromZonedDateTime(fieldsChanged.startDate.from, dateTimeFormat),
          to: formatFromZonedDateTime(fieldsChanged.startDate.to, dateTimeFormat),
        },
      }),
      ...(fieldsChanged.endDate && {
        endDate: {
          from: formatFromZonedDateTime(fieldsChanged.endDate.from, dateTimeFormat),
          to: formatFromZonedDateTime(fieldsChanged.endDate.to, dateTimeFormat),
        },
      }),
      ...(fieldsChanged.departureMeetingPointId && {
        departureMeetingPoint: {
          from: getMeetingPointLabelById(meetingPoints, fieldsChanged.departureMeetingPointId.from),
          to: getMeetingPointLabelById(meetingPoints, fieldsChanged.departureMeetingPointId.to),
        },
      }),
      ...(fieldsChanged.arrivalMeetingPointId && {
        arrivalMeetingPoint: {
          from: getMeetingPointLabelById(meetingPoints, fieldsChanged.arrivalMeetingPointId.from),
          to: getMeetingPointLabelById(meetingPoints, fieldsChanged.arrivalMeetingPointId.to),
        },
      }),
    },
  };
};

const processCommentResponse = (comment: OrderComment) => {
  return {
    ...comment,
    createdAt: formatFromZonedDateTime(comment.createdAt, dateTimeFormat),
  };
};

export const getOrders = (params: OrdersQueryParams, pageParam?: number): Promise<Order[]> =>
  BTServer.get<OrderQueryResponse[]>("/admin/orders", {
    params: {
      ...params,
      offset: pageParam ? pageParam * params.row_limit : undefined,
    },
  }).then<Order[]>((res) => res.data.map(processOrderResponse));

export const getOrder = (id: string): Promise<Order> =>
  BTServer.get<OrderQueryResponse>(`/admin/orders/${id}`).then<Order>((res) =>
    processOrderResponse(res.data)
  );

export const cancelOrder = (id: string) => BTServer.post(`/admin/orders/${id}/cancel`);

export const reopenCancelledOrder = (id: string) => BTServer.post(`/admin/orders/${id}/reopen`);

export const updateOrder = (id: string, changes: OrderUpdateValues): Promise<Order> =>
  BTServer.put(`/admin/orders/${id}`, { ...changes }).then<Order>((res) =>
    processOrderResponse(res.data)
  );

export const postOrder = (params: CreateOrderQuery): Promise<Order> =>
  BTServer.post(`/admin/orders`, { ...params }).then<Order>((res) =>
    processOrderResponse(res.data)
  );

export const postOrderPlatform = (params: CreateOrderPlatformQuery) =>
  BTServer.post(`/admin/orders/platform`, {
    ...params,
  }).then<Order>((res) => processOrderResponse(res.data));

export const getOrderHistory = (
  id: string,
  meetingPoints: MeetingPoint[]
): Promise<OrderHistory[]> =>
  BTServer.get<OrderHistoryQueryResponse[]>(`/admin/orders/${id}/history`).then<OrderHistory[]>(
    (res) => {
      return res.data.map((orderHistory) => {
        return processHistoryResponse(orderHistory, meetingPoints);
      });
    }
  );

export const getOrderComments = (id: string): Promise<OrderComment[]> =>
  BTServer.get<OrderComment[]>(`/admin/orders/${id}/comments`).then<OrderComment[]>((res) => {
    return res.data.map((orderComment) => processCommentResponse(orderComment));
  });

export const postOrderComment = (id: string, text: string): Promise<OrderComment> =>
  BTServer.post<OrderComment>(`/admin/orders/${id}/comments`, {
    text,
  }).then<OrderComment>((res) => processCommentResponse(res.data));

export const resendEmailConfirmation = (id: string) =>
  BTServer.post(`/admin/orders/${id}/send-confirmation`);
