import React from "react";
import { GppBad, GppGood } from "@mui/icons-material";

interface CellPropsType {
  value: boolean;
}

function CustomCellCancellationOption({ value }: CellPropsType) {
  return value ? <GppGood color="success" /> : <GppBad color="error" />;
}

export default CustomCellCancellationOption;
