import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

function OrderCreationHeader() {
  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      <Grid item flexDirection="column">
        <Typography variant="h3" gutterBottom display="inline">
          Create new order
        </Typography>
        <Breadcrumbs aria-label="Breadcrumb">
          <Typography>Customer</Typography>
          <Link component={NavLink} to="/orders">
            Orders
          </Link>
          <Typography>Create New Order</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}

export default OrderCreationHeader;
