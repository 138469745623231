import { GppBad, GppGood } from "@mui/icons-material";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomCellStatus from "../../../components/customCell/Status";
import { Order } from "../../../types/orders";

function DetailsTable({ row }: { row: Order }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Cancellation Option </TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Days Duration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="center">
              <CustomCellStatus value={row.status} />
            </TableCell>
            <TableCell align="center">
              {row.cancellationOption ? <GppGood color="success" /> : <GppBad color="error" />}
            </TableCell>
            <TableCell align="center">{row.totalPriceInclTaxes}€</TableCell>
            <TableCell align="center">{row.createdAt.format("DD/MM/YYYY HH:mm")}</TableCell>
            <TableCell align="center">{row.totalDays}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DetailsTable;
