import styled from "styled-components/macro";

import { Chip as MuiChip } from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { spacing, SpacingProps } from "@mui/system";
import { MovementType } from "../../types/movements";

interface ChipProps extends SpacingProps {
  label: string;
  size?: string;
}
const Chip = styled(MuiChip)<ChipProps>`
  ${spacing};
  background: ${(props) => {
    switch (props.label) {
      case MovementType.IN:
        return green[500];
      case MovementType.OUT:
        return red[800];
      default:
        return grey[200];
    }
  }};
  color: ${(props) => props.theme.palette.common.white};
  font-weight: 600;
  min-width: 60px;
`;

interface CellPropsType {
  value: MovementType.IN | MovementType.OUT;
}

function CustomCellMovementType({ value }: CellPropsType) {
  return <Chip size="small" mr={1} mb={1} label={value} sx={{ transition: "none" }} />;
}

export default CustomCellMovementType;
