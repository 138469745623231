import { Calendar, MapPin, ShoppingCart } from "react-feather";

import { SidebarItemsType } from "../../types/sidebar";

const customerSection = [
  {
    href: "/orders",
    icon: ShoppingCart,
    title: "Orders",
  },
] as SidebarItemsType[];

const operationSection = [
  {
    href: "/movements",
    icon: Calendar,
    title: "Daily Movements",
  },
] as SidebarItemsType[];

const sitesSection = [
  {
    href: "/sites",
    icon: MapPin,
    title: "Sites",
  },
] as SidebarItemsType[];

const navItems = (isAdmin: boolean) => {
  const items = [
    {
      title: "customer",
      pages: customerSection,
    },
    {
      title: "operation",
      pages: operationSection,
    },
  ];
  if (isAdmin) {
    items.push({
      title: "sites",
      pages: sitesSection,
    });
  }
  return items;
};

export default navItems;
