import React from "react";
import styled from "styled-components/macro";

import { Chip as MuiChip } from "@mui/material";
import { green, grey, orange, red } from "@mui/material/colors";
import { spacing, SpacingProps } from "@mui/system";

interface ChipProps extends SpacingProps {
  label: string;
  size?: string;
}
const Chip = styled(MuiChip)<ChipProps>`
  ${spacing};

  background: ${(props) => {
    switch (props.label) {
      case "created":
        return grey[600];
      case "accepted":
        return green[500];
      case "pending":
        return orange[700];
      case "failed":
        return red[600];
      case "cancelled":
        return red[800];
      default:
        return grey[200];
    }
  }};
  color: ${(props) => props.theme.palette.common.white};
  font-weight: 600;
`;
function CustomCellStatus({ value }: { value: string }) {
  return <Chip size="small" mr={1} mb={1} label={value} sx={{ transition: "none" }} />;
}

export default CustomCellStatus;
