import React from "react";
import { Button, Divider, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import NorthEastIcon from "@mui/icons-material/NorthEast";

import NotAllowed from "../../components/NotAllowed";
import { SitesActions } from "../../types/auth";
import { Palette } from "../../types/themes";
import { isSiteAuthorized } from "../../utils/auth";
import ParkingCapacity from "./parkingCapacity";

const SitePage = () => {
  return (
    <Paper sx={{ height: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Stack>
            <Typography sx={{ fontSize: 16, fontWeight: "bold", p: 3, textAlign: "center" }}>
              Parking capacity
            </Typography>
            <Divider variant="middle" />
            {isSiteAuthorized(SitesActions.ParkingCapacity) ? <ParkingCapacity /> : <NotAllowed />}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={6}></Grid>
        <Grid item xs={12} textAlign="center">
          <Divider variant="middle" sx={{ my: 3 }} />
          <Button
            sx={{ bgcolor: Palette.PaperBackground }}
            variant="outlined"
            endIcon={<NorthEastIcon />}
          >
            <Link
              href="https://bluevalet.retool.com/apps/621ff6a2-52c1-11ed-9dd4-dbcfccc7d0ab"
              target="_blank"
              underline="none"
            >
              See the full recap on calendar
            </Link>
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SitePage;
