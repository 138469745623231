import moment, { Moment } from "moment";
import "moment-timezone";

export const dateTimeFormat = "DD/MM/YYYY HH:mm";

export const convertZonedDateTimeToMomentDate = (date: string) => {
  const dateParts = date.match(/(.+)\[(.+)\]/);
  const dateTime = dateParts?.[1] || date;
  const timezone = dateParts?.[2] || "UTC";

  return moment.tz(dateTime, timezone);
};

export const convertMomentDateToZonedDateTime = (date: Moment) => {
  return date.format("YYYY-MM-DDTHH:mm:ssZ") + `[${date.tz()}]`;
};

export const formatFromZonedDateTime = (date: string, format: string) => {
  return convertZonedDateTimeToMomentDate(date).format(format);
};
