export enum Palette {
  Success = "success.main",
  Error = "error.main",
  Warning = "warning.main",
  Grey = "grey",
  PaperBackground = "background.paper",
  TransparencyGreen = "transparency.green",
  TransparencyRed = "transparency.red",
  TransparencyBlue = "transparency.blue",
}

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
    transparency?: TransparencyColor;
  };
  alert: ColorBgType;
  header: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
  };
  comments: {
    background: string;
    inputBackground: string;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};

type TransparencyColor = {
  green: string;
  red: string;
};

export type ColorBgType = {
  color: string;
  background: string;
};
export enum THEMES {
  DEFAULT = "DEFAULT",
  DARK = "DARK",
}
