import moment, { now } from "moment";
import { Grid, TextField, Button } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { selectDailyMovementsState, setDate } from "../../redux/slices/dailyMovements";
import { Palette } from "../../types/themes";

const HeaderDatePicker = ({ displayShortCutButton }: { displayShortCutButton: boolean }) => {
  const { date: selectedDate } = useAppSelector(selectDailyMovementsState);
  const dispatch = useAppDispatch();

  return (
    <Grid item container flexDirection="column" spacing={2}>
      <Grid item sx={{ height: 50, display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => dispatch(setDate(moment(selectedDate).add(-1, "day").format()))}
          sx={{ bgcolor: Palette.PaperBackground, mx: 1 }}
          variant="outlined"
        >
          <ArrowLeft fontSize="large" />
        </Button>
        <DatePicker
          desktopModeMediaQuery="@media (min-width: 720px)"
          inputFormat="dd/MM/yyyy"
          onChange={(newDate) => dispatch(setDate(moment(newDate).format()))}
          value={selectedDate}
          InputProps={{ sx: { height: "100%" } }}
          renderInput={(params) => (
            <TextField {...params} sx={{ bgcolor: Palette.PaperBackground }} />
          )}
        />
        <Button
          onClick={() => dispatch(setDate(moment(selectedDate).add(1, "day").format()))}
          sx={{ bgcolor: Palette.PaperBackground, mx: 1 }}
          variant="outlined"
        >
          <ArrowRight fontSize="large" />
        </Button>
      </Grid>
      {displayShortCutButton && (
        <Grid item display="flex" justifyContent="center">
          <Button
            onClick={() => dispatch(setDate(moment(now()).format()))}
            sx={{
              bgcolor: Palette.PaperBackground,
              mx: 1,
              borderRadius: 4,
              height: 30,
            }}
            variant="outlined"
            size="small"
            disabled={
              moment(selectedDate).format("YYYY-MM-DD") === moment(now()).format("YYYY-MM-DD")
            }
          >
            Today
          </Button>
          <Button
            onClick={() => dispatch(setDate(moment(now()).add(1, "day").format()))}
            sx={{
              bgcolor: Palette.PaperBackground,
              mx: 1,
              borderRadius: 4,
              height: 30,
            }}
            variant="outlined"
            disabled={
              moment(selectedDate).format("YYYY-MM-DD") ===
              moment(now()).add(1, "day").format("YYYY-MM-DD")
            }
          >
            Tomorrow
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderDatePicker;
