import { Divider, FormControlLabel, Grid, Stack, Switch, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { SyntheticEvent } from "react";
import {
  CreateOrderFormikValues,
  OrderFormikValues,
  UpdateOrderFormikValues,
} from "../../../types/orders";
import OrderNumberInput from "./OrderNumberInput";
import PlatformInput from "./PlatformInput";

interface PlatformFormProps {
  readOnly?: boolean;
}

function PlatformForm({ readOnly }: PlatformFormProps) {
  const { errors, touched, handleBlur, handleChange, setFieldValue, values } = useFormikContext<
    CreateOrderFormikValues | UpdateOrderFormikValues
  >();

  const handleSwitchChange = (e: SyntheticEvent<Element, Event>, checked: boolean) => {
    handleChange(e);
    if (!checked) {
      setFieldValue(OrderFormikValues.platformId, undefined, false);
      setFieldValue(OrderFormikValues.externalOrderNumber, undefined, false);
    }
  };

  return (
    <Stack sx={{ height: "100%" }}>
      <Typography sx={{ fontSize: 16, fontWeight: "bold", p: 3, textAlign: "center" }}>
        Platform
      </Typography>
      <Divider variant="middle" />
      <Stack p={5}>
        {!readOnly && (
          <FormControlLabel
            value={values.isPlatform}
            checked={values.isPlatform}
            name={OrderFormikValues.isPlatform}
            onChange={handleSwitchChange}
            control={<Switch />}
            label="Platform"
          />
        )}
        <Grid container spacing={{ xs: 5, lg: 10 }}>
          <Grid item xs={6}>
            <PlatformInput
              setFieldValue={setFieldValue}
              value={values.platformId}
              errorMessage={errors.platformId}
              disabled={!values.isPlatform}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <OrderNumberInput
              value={values.externalOrderNumber}
              errorMessage={errors.externalOrderNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.externalOrderNumber}
              disabled={!values.isPlatform}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default PlatformForm;
