import { MarkEmailRead, Phone, Visibility } from "@mui/icons-material";
import { IconButton as MuiIconButton, Stack, SvgIcon } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CopyToClipboardState } from "react-use/lib/useCopyToClipboard";
import styled from "styled-components/macro";
import { ReactComponent as EmailClipboard } from "../../../../../assets/img/EmailClipboardCopy.svg";

const IconButton = styled(MuiIconButton)`
  background-color: ${(props) => props.theme.palette.transparency.blue};
  :hover {
    background-color: rgba(30, 136, 229, 0.15);
  }
`;

interface CardActionProps {
  phoneNumber: string;
  email: string;
  orderId: string;
  copyState: CopyToClipboardState;
  copyToClipboard: (email: string) => void;
}

const MovementCardActions = ({
  phoneNumber,
  email,
  orderId,
  copyState,
  copyToClipboard,
}: CardActionProps) => {
  const [copyEmailIsTouched, setCopyEmailIsTouched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (copyEmailIsTouched && copyState.value === email) {
      toast.success(`${email} copied in clipboard`);
    }
  }, [copyState.value, copyEmailIsTouched, email]);

  function handleCopyEmail() {
    copyToClipboard(email);
    setCopyEmailIsTouched(true);
  }

  return (
    <Stack flexDirection="row" justifyContent="space-around" pb={2}>
      <IconButton onClick={() => (window.location.href = `tel:${phoneNumber}`)}>
        <Phone color="primary" />
      </IconButton>
      <IconButton onClick={handleCopyEmail}>
        {copyState.value === email && copyEmailIsTouched ? (
          <MarkEmailRead color="success" />
        ) : (
          <SvgIcon>
            <EmailClipboard height={30} width={30} y={"3px"} x={"2px"} viewBox={"0 0 24 24"} />
          </SvgIcon>
        )}
      </IconButton>
      <IconButton onClick={() => navigate(`/orders/${orderId}`)}>
        <Visibility color="primary" />
      </IconButton>
    </Stack>
  );
};
export default MovementCardActions;
