import {
  Movement,
  MovementQueryParams,
  MovementQueryResponse,
  MovementType,
} from "../types/movements";
import { MeetingPoint } from "../types/sites";
import { convertZonedDateTimeToMomentDate } from "../utils/date";
import BTServer from "./axiosInterceptors/BTServer";

const processMovementResponse = (
  movement: MovementQueryResponse,
  meetingPoints: MeetingPoint[]
) => {
  return {
    ...movement,
    id: `${movement.number}_${movement.movementType}`,
    orderId: movement.id,
    startDate: convertZonedDateTimeToMomentDate(movement.startDate),
    endDate: convertZonedDateTimeToMomentDate(movement.endDate),
    movementDate: convertZonedDateTimeToMomentDate(movement.movementDate),
    meetingPoint: meetingPoints.find(
      (meetingPoint) =>
        meetingPoint.id ===
        (movement.movementType === MovementType.IN
          ? movement.departureMeetingPointId
          : movement.arrivalMeetingPointId)
    ),
  };
};

export const getDailyMovements = (
  params: MovementQueryParams,
  page: number,
  meetingPoints: MeetingPoint[]
): Promise<Movement[]> =>
  BTServer.get<MovementQueryResponse[]>(`/admin/orders/movements`, {
    params: {
      ...params,
      offset: page ? params?.row_limit * page : undefined,
    },
  }).then<Movement[]>((res) => {
    return res.data.map((movement) => processMovementResponse(movement, meetingPoints));
  });
