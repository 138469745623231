import axios from "axios";
import { BTSBaseUrl } from "../../config";
import keycloak from "../../keycloak";

const BTServer = axios.create();

BTServer.interceptors.request.use(
  (config) => {
    config.baseURL = BTSBaseUrl;
    if (keycloak.token) {
      config.headers.Authorization = `Bearer ${keycloak.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BTServer.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || "Something went wrong")
);

export default BTServer;
