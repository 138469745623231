import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { labelLanguage } from "../../../../config";
import { useSites } from "../../../../contexts/SiteContext";
import { OrderFormikValues } from "../../../../types/orders";
import { Site } from "../../../../types/sites";

interface SiteInputProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  value: number | undefined;
  initialValue: number | undefined;
  errorMessage: string | undefined;
  isEditable: boolean;
}

const SiteInput = ({
  setFieldValue,
  value,
  initialValue,
  errorMessage,
  isEditable,
}: SiteInputProps) => {
  const { sites } = useSites();

  const getSiteLabel = (siteId: number) =>
    sites?.find((site: Site) => site.id === siteId)?.label[labelLanguage] || siteId;

  const generateSiteOptions = () => {
    const siteOptions = sites.map((site) => (
      <MenuItem value={`${site.id}`}>{getSiteLabel(site.id)}</MenuItem>
    ));
    siteOptions.unshift(<MenuItem selected disabled hidden />);

    return siteOptions;
  };

  const handleChange = (e: SelectChangeEvent<string>) => {
    const newValue = parseInt(e.target.value);

    const selectedSiteData = sites.find((site) => site.id === newValue);
    const firstMeetingPointId = selectedSiteData?.meetingPoints[0].id;

    setFieldValue(OrderFormikValues.departureMeetingPointId, firstMeetingPointId);
    setFieldValue(OrderFormikValues.arrivalMeetingPointId, firstMeetingPointId);
    setFieldValue(e.target.name, newValue);
  };

  return (
    <FormControl variant="standard" fullWidth error={!!errorMessage}>
      <InputLabel id="site-select">Site</InputLabel>
      <Select
        inputProps={{ name: OrderFormikValues.siteId, id: "site-select" }}
        onChange={handleChange}
        value={value ? `${value}` : ""}
        readOnly={!isEditable}
      >
        {!!initialValue ? (
          <option value={value}>{getSiteLabel(initialValue)}</option>
        ) : (
          generateSiteOptions()
        )}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default SiteInput;
