import { Moment } from "moment";
import { MeetingPoint } from "./sites";
import { OrderLuggage } from "./orders";

interface BaseMovement {
  movementType: MovementType;
  id: string;
  orderId: string;
  number: string;
  siteId: number;
  arrivalFlightNumber: string | null;
  departureMeetingPointId: number;
  arrivalMeetingPointId: number;
  passengers: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  vehicleModel: string;
  vehicleColor: string;
  luggage?: OrderLuggage;
  parkName?: string;
  parkLocation?: string;
}

export interface Movement extends BaseMovement {
  movementDate: Moment;
  startDate: Moment;
  endDate: Moment;
  meetingPoint?: MeetingPoint;
}

export enum MovementType {
  IN = "IN",
  OUT = "OUT",
}

// HTTP

export interface MovementQueryParams {
  date: string;
  site: number;
  row_limit: number;
}

export interface MovementQueryResponse extends BaseMovement {
  movementDate: string;
  startDate: string;
  endDate: string;
  departureMeetingPointId: number;
  arrivalMeetingPointId: number;
}
