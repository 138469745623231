export function getDiffWithSymbol(from: number, to: number): string {
  const sign = from < to ? "+" : "";
  const diff = to - from;
  return sign + diff;
}

export function capitalizeAndSplitCamelCase(sentence: string): string {
  return sentence
    .split(/(?=[A-Z])/)
    .map((p) => {
      return p[0].toUpperCase() + p.slice(1);
    })
    .join(" ");
}
