import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";
import { OrderLuggage } from "../../../types/orders";

interface LuggageInputProps {
  onChange: (e: SelectChangeEvent) => void;
  value: string | undefined;
}

const LuggageInput = ({ onChange, value }: LuggageInputProps) => {
  return (
    <FormControl>
      <FormLabel id="luggage">Luggage</FormLabel>
      <RadioGroup aria-labelledby="luggage" name="luggage" value={value} onChange={onChange}>
        <FormControlLabel
          control={<Radio size="small" />}
          value={OrderLuggage.NoneOrCarryOnBag}
          label="None or carry on bag"
        />
        <FormControlLabel
          control={<Radio size="small" />}
          value={OrderLuggage.CheckedBag}
          label="Checked bag"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default LuggageInput;
