import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import { VariantType } from "../types/themes";

declare module "@mui/material/styles" {
  interface Theme extends VariantType {}
}

const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      alert: themeConfig.alert,
      header: themeConfig.header,
      sidebar: themeConfig.sidebar,
      comments: themeConfig.comments,
    }
  );
};

export default createTheme;
