import React from "react";
import { MeetingPoint, Site } from "../types/sites";
import { useQuery } from "react-query";
import { getSites } from "../api/sites";
import { getAuthorizedSites } from "../utils/auth";

type SitesProviderProps = {
  children: React.ReactNode;
};

type SitesContextState = {
  sites: Array<Site>;
  authorizedSites: Array<Site>;
  currentSite?: Site;
  setCurrentSite: (site: Site) => void;
  meetingPoints: MeetingPoint[];
  isLoading: boolean;
  isError: boolean;
};

const initialState: SitesContextState = {
  sites: [],
  authorizedSites: [],
  currentSite: undefined,
  setCurrentSite: (site: Site) => {},
  meetingPoints: [],
  isLoading: false,
  isError: false,
};

const SitesContext = React.createContext(initialState);

const LOCAL_STORAGE_KEY = "site";

export function SitesProvider({ children }: SitesProviderProps) {
  const initialState = () => {
    const storedSite = localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedSite ? JSON.parse(storedSite) : null;
  };

  const [currentSite, _setCurrentSite] = React.useState<Site>(initialState());

  const sortSitesByLabelName = (sites: Site[]): Site[] => {
    return sites.sort((a, b) => a.label.fr.normalize().localeCompare(b.label.fr.normalize()));
  };

  const {
    data: sitesData,
    isLoading,
    isError,
  } = useQuery("sites", getSites, {
    staleTime: 600000,
    onSuccess: (sites) => {
      const sortedSites = sortSitesByLabelName(sites);
      const authorizedSites = getAuthorizedSites(sortedSites);
      if (!currentSite) {
        setCurrentSite(authorizedSites?.[0]);
      }
    },
  });

  const sites = sitesData ? sortSitesByLabelName(sitesData) : [];
  const sitesMeetingPoints = sites.map((site) => site.meetingPoints);
  const meetingPoints = ([] as MeetingPoint[]).concat(...sitesMeetingPoints);
  const authorizedSites = getAuthorizedSites(sites);

  const setCurrentSite = (site: Site) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(site));
    _setCurrentSite(site);
  };

  return (
    <SitesContext.Provider
      value={{
        sites,
        authorizedSites,
        currentSite,
        setCurrentSite,
        meetingPoints,
        isLoading,
        isError,
      }}
    >
      {children}
    </SitesContext.Provider>
  );
}

export const useSites = () => React.useContext(SitesContext);
