import { TextField } from "@mui/material";
import { ChangeEvent, FocusEventHandler } from "react";
import { OrderFormikValues } from "../../../types/orders";

interface OrderNumberInputProps {
  onChange: (e: ChangeEvent) => void;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string | null | undefined;
  touched: boolean | undefined;
  errorMessage: string | undefined;
  disabled: boolean;
  readOnly?: boolean;
}

const OrderNumberInput = ({
  value,
  errorMessage,
  onChange,
  onBlur,
  touched,
  disabled,
  readOnly,
}: OrderNumberInputProps) => {
  return (
    <TextField
      name={OrderFormikValues.externalOrderNumber}
      label="External Order Number"
      value={value || ""}
      onChange={onChange}
      onBlur={onBlur}
      error={touched && !!errorMessage}
      fullWidth
      helperText={touched && errorMessage}
      variant="standard"
      disabled={disabled}
      InputProps={{
        readOnly,
      }}
    />
  );
};

export default OrderNumberInput;
