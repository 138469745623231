import {
  AirlineSeatReclineNormal,
  DirectionsCar,
  Person,
  FlightLand,
  Event,
  Place,
  LocalParking,
} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import moment, { Moment } from "moment";
import { dateTimeFormat } from "../../../../../utils/date";

interface CardInfoProps {
  isIn: boolean;
  customerName: string;
  passengers: number;
  arrivalFlight: string | null;
  endDate: Moment;
  vehicleModel: string;
  vehicleColor: string;
  meetingPointName?: string;
  parkName?: string;
  parkLocation?: string;
}

const CardInfo = ({
  isIn,
  customerName,
  passengers,
  arrivalFlight,
  endDate,
  vehicleModel,
  vehicleColor,
  meetingPointName,
  parkName,
  parkLocation,
}: CardInfoProps) => {
  const join = (...args: Array<string | undefined>): string => args.filter((e) => e).join(" – ");

  const data = [
    {
      icon: <Person />,
      value: customerName,
    },
    {
      icon: <AirlineSeatReclineNormal />,
      value: `${passengers} passengers`,
    },
    {
      icon: <Place />,
      value: meetingPointName,
    },
    {
      icon: <DirectionsCar />,
      value: join(vehicleModel, vehicleColor),
    },
  ];
  if (isIn) {
    data.push({
      icon: <Event sx={{ color: "red" }} />,
      value: moment(endDate).format(dateTimeFormat),
    });
  } else {
    if (arrivalFlight) {
      data.push({
        icon: <FlightLand />,
        value: arrivalFlight,
      });
    }
    if (parkName || parkLocation) {
      data.push({
        icon: <LocalParking />,
        value: join(parkName, parkLocation),
      });
    }
  }

  return (
    <Grid container p={3} flexDirection="column" spacing={2}>
      {data.map((info, i) => {
        return (
          <Grid key={i} item sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            {info.icon}
            <Typography fontWeight="bold">{info.value}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CardInfo;
