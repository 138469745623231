import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment, { now } from "moment";
import { RootState } from "../store";

export type DailyMovementsState = {
  date: string;
  rowLimit: number;
};

const initialState: DailyMovementsState = {
  date: moment(now()).format(),
  rowLimit: 25,
};

export const dailyMovementsSlice = createSlice({
  name: "dailyMovements",
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setDate, resetState } = dailyMovementsSlice.actions;

export const selectDailyMovementsState = (state: RootState) => state.dailyMovements;

export default dailyMovementsSlice.reducer;
