import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { getPlatforms } from "../../../api/platforms";
import { OrderFormikValues } from "../../../types/orders";

interface PlatformInputProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  value: number | null | undefined;
  errorMessage: string | undefined;
  disabled: boolean;
  readOnly?: boolean;
}

const PlatformInput = ({
  value,
  errorMessage,
  setFieldValue,
  disabled,
  readOnly,
}: PlatformInputProps) => {
  const { data: platformData } = useQuery("platforms", getPlatforms, {
    onError: () => {
      toast.error("An error occurred while fetching platforms data");
    },
  });

  const generatePlatformOptions = () => {
    if (platformData) {
      const platformOptions = platformData.map((platform, i) => (
        <MenuItem key={i} value={`${platform.id}`}>
          {platform.label}
        </MenuItem>
      ));
      platformOptions.unshift(
        <MenuItem key={platformOptions.length + 1} selected disabled hidden />
      );

      return platformOptions;
    }
  };

  return (
    <FormControl variant="standard" fullWidth error={!!errorMessage}>
      <InputLabel id="platform-select">Platform</InputLabel>
      <Select
        inputProps={{
          name: OrderFormikValues.platformId,
          id: "platform-select",
        }}
        readOnly={readOnly}
        onChange={(e) => setFieldValue(e.target.name, parseInt(e.target.value))}
        value={value ? `${value}` : ""}
        disabled={disabled}
      >
        {generatePlatformOptions()}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default PlatformInput;
