import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";

const GridToolbar = styled(GridToolbarContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;

interface CustomToolbarProps {
  refetch: () => void;
  isFetching: boolean;
}

function CustomToolbar({ refetch, isFetching }: CustomToolbarProps) {
  return (
    <GridToolbar>
      <LoadingButton sx={{ m: 1 }} loading={isFetching} onClick={refetch} variant="contained">
        Refresh
      </LoadingButton>
    </GridToolbar>
  );
}

export default CustomToolbar;
