import { FocusEvent } from "react";
import { ChangeEvent, useState } from "react";
import { Sync, FlightLand, LocationOn } from "@mui/icons-material";
import { Grid, InputAdornment, Typography, TextField, Paper } from "@mui/material";
import { useQuery } from "react-query";
import { getArrivalFlightInfo } from "../../../../api/flights";
import { formatFromZonedDateTime } from "../../../../utils/date";

interface ArrivalFlightInputProps {
  value: string | null | undefined;
  endDate: string | undefined;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  readOnly: boolean;
  error?: boolean;
  helperText?: string;
}

const ArrivalFlightInput = ({
  value,
  endDate,
  handleChange,
  handleBlur,
  readOnly,
  error,
  helperText,
}: ArrivalFlightInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const flightNumber = value || "";

  const {
    data: flightData,
    isError,
    isLoading,
  } = useQuery(
    ["flight", flightNumber, endDate],
    () => getArrivalFlightInfo(flightNumber, endDate),
    {
      enabled: Boolean(endDate) && flightNumber?.length > 3,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const colorStatus = isError
    ? "error"
    : flightData?.[0]
    ? "success"
    : flightNumber?.length > 3
    ? "warning"
    : "secondary";
  const currentFlightData = flightData?.[0] && flightNumber?.length > 3 ? flightData?.[0] : null;

  return (
    <Grid container>
      <TextField
        name="arrivalFlightNumber"
        label="Arrival Flight Number"
        variant="standard"
        color={value ? colorStatus : "primary"}
        sx={{ width: "100%" }}
        error={error}
        helperText={helperText}
        value={flightNumber}
        onChange={handleChange}
        onFocusCapture={() => setIsFocused(true)}
        onBlur={(e) => {
          setIsFocused(false);
          handleBlur(e);
        }}
        InputProps={{
          readOnly: readOnly,
          endAdornment: (
            <InputAdornment position="end">
              {isLoading ? (
                <Sync />
              ) : (
                <FlightLand color={value ? colorStatus : isFocused ? "secondary" : "inherit"} />
              )}
            </InputAdornment>
          ),
        }}
      />
      {isFocused && currentFlightData && (
        <Paper
          elevation={24}
          variant="outlined"
          sx={{
            position: "relative",
            bgcolor: "background.default",
            width: "100%",
          }}
        >
          <Grid container flexDirection="column" p={2} spacing={2}>
            <Grid item container alignItems="center">
              <LocationOn sx={{ mr: 2 }} />
              <Typography>{`${currentFlightData.arrivalAirport.city} (${currentFlightData.arrivalAirport.code})`}</Typography>
            </Grid>
            <Grid item container>
              <FlightLand sx={{ mr: 2 }} />
              <Typography>
                {formatFromZonedDateTime(currentFlightData.arrivalTime, "DD/MM/YYYY - HH:mm")}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default ArrivalFlightInput;
