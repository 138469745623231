import { Divider, Grid, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { TextField, Typography } from "@mui/material";
import { OrderFormikValues, UpdateOrderFormikValues } from "../../../types/orders";
import LuggageInput from "./LuggageInput";

interface OperationFormProps {
  isEditable: boolean;
}

const Index = ({ isEditable }: OperationFormProps) => {
  const { errors, touched, handleChange, handleBlur, values } =
    useFormikContext<UpdateOrderFormikValues>();

  return (
    <Stack sx={{ height: "100%" }}>
      <Typography sx={{ fontSize: 16, fontWeight: "bold", p: 3, textAlign: "center" }}>
        Operation
      </Typography>
      <Divider variant="middle" />
      <Grid container spacing={5} p={5}>
        <Grid item xs={6}>
          <TextField
            name={OrderFormikValues.parkName}
            label="Park name"
            value={values.parkName || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.parkName && !!errors.parkName}
            fullWidth
            helperText={touched.parkName && errors.parkName}
            variant="standard"
            InputProps={{
              readOnly: !isEditable,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name={OrderFormikValues.parkLocation}
            label="Park location"
            value={values.parkLocation || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.parkLocation && !!errors.parkLocation}
            helperText={touched.parkLocation && errors.parkLocation}
            variant="standard"
            InputProps={{
              readOnly: !isEditable,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LuggageInput onChange={handleChange} value={values.luggage || ""} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Index;
