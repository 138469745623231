import React from "react";
import { Stack, Typography } from "@mui/material";
import { People, Person } from "@mui/icons-material";

interface CellPropsType {
  value: number;
}

function CustomCellPassengers({ value }: CellPropsType) {
  return (
    <Stack flexDirection="row" alignItems="center">
      {value === 1 ? (
        <Person sx={{ opacity: 0.9 }} fontSize="small" />
      ) : (
        <People sx={{ opacity: 0.9 }} fontSize="small" />
      )}
      <Typography fontWeight="bold" m={1}>
        {value}
      </Typography>
    </Stack>
  );
}

export default CustomCellPassengers;
