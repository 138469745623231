import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import { AuthClientEvent } from "@react-keycloak/core";
import keycloak from "./keycloak";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { ThemeProvider } from "./contexts/ThemeContext";
import { store } from "./redux/store";
import { Provider } from "react-redux";

import "./license";

import "./i18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});

const handleKeycloakEvent = (event: AuthClientEvent) => {
  if (event === "onReady") {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  } else if (
    event === "onAuthLogout" ||
    event === "onAuthError" ||
    event === "onAuthRefreshError"
  ) {
    keycloak.logout();
  }
};

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
      }}
      onEvent={handleKeycloakEvent}
    >
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider>
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools position="bottom-right" />
            </QueryClientProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
