import { Moment } from "moment";

// DOMAIN

interface BaseOrder {
  id: string;
  number: string;
  siteId: number;
  arrivalFlightNumber: string | null;
  departureMeetingPointId: number;
  arrivalMeetingPointId: number;
  passengers: number;
  cancellationOption: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  zipCode: string;
  city: string;
  lang: string;
  totalPriceExclTaxes: number;
  totalPriceInclTaxes: number;
  totalDays: number;
  status: OrderStatus;
  vehicleModel: string;
  vehicleColor: string;
  platformId: number | null;
  externalOrderNumber: string | null;
  luggage: string | null;
  parkName: string | null;
  parkLocation: string | null;
}

export interface Order extends BaseOrder {
  startDate: Moment;
  endDate: Moment;
  createdAt: Moment;
}

export enum OrderStatus {
  Created = "created",
  Pending = "pending",
  Failed = "failed",
  Accepted = "accepted",
  Cancelled = "cancelled",
}

export enum OrderLuggage {
  NoneOrCarryOnBag = "none_carry_on_bag",
  CheckedBag = "checked_bag",
}

export enum OrderFields {
  address = "address",
  arrivalFlightNumber = "arrivalFlightNumber",
  cancellationOption = "cancellationOption",
  city = "city",
  createdAt = "createdAt",
  email = "email",
  endDate = "endDate",
  firstName = "firstName",
  id = "id",
  lang = "lang",
  lastName = "lastName",
  number = "number",
  passengers = "passengers",
  phone = "phone",
  siteId = "siteId",
  startDate = "startDate",
  status = "status",
  totalDays = "totalDays",
  totalPriceExclTaxes = "totalPriceExclTaxes",
  totalPrice = "totalPriceInclTaxes",
  zipCode = "zipCode",
  vehicleModel = "vehicleModel",
  vehicleColor = "vehicleColor",
  meetingPoint = "meetingPoint",
  externalOrderNumber = "externalOrderNumber",
  platformId = "platformId",
  luggage = "luggage",
  parkName = "parkName",
  parkLocation = "parkLocation",
}

// HTTP

export interface OrderQueryResponse extends BaseOrder {
  startDate: string;
  endDate: string;
  createdAt: string;
}

export interface OrdersQueryParams {
  search?: string;
  column?: string;
  row_limit: number;
  sort?: string;
  created_date_range_begin?: string;
  created_date_range_end?: string;
  offset?: number;
  [key: string]: any;
}

export interface OrderUpdateValues {
  startDate?: string;
  endDate?: string;
  passengers?: number;
  departureMeetingPointId?: number;
  arrivalMeetingPointId?: number;
  arrivalFlightNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  city?: string;
  zipCode?: string;
  vehicleModel?: string;
  vehicleColor?: string;
}

export interface CreateOrderQuery {
  siteId: number;
  departureMeetingPointId: number;
  arrivalMeetingPointId: number;
  startDate: string;
  endDate: string;
  passengers: number;
  arrivalFlightNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  city: string;
  zipCode: string;
  vehicleModel: string;
  vehicleColor: string;
  cancellationOption: boolean;
  lang: string;
}

export interface CreateOrderPlatformQuery extends Omit<CreateOrderQuery, "email"> {
  platformId: number;
  externalOrderNumber: string;
  email: string | undefined;
}

// ORDER HISTORY

export interface OrderHistory {
  date: string;
  author: string;
  fieldsChanged: FieldsChanged;
}

export interface OrderHistoryQueryResponse {
  date: string;
  author: string;
  fieldsChanged: FieldsChangedQueryResponse;
}

export interface FieldsChangedQueryResponse extends BaseFieldsChanged {
  departureMeetingPointId?: FieldHistory<number>;
  arrivalMeetingPointId?: FieldHistory<number>;
}
export interface FieldsChanged extends BaseFieldsChanged {
  departureMeetingPoint?: FieldHistory<string | undefined>;
  arrivalMeetingPoint?: FieldHistory<string | undefined>;
}
export interface BaseFieldsChanged {
  passengers?: FieldHistory<number>;
  status?: FieldHistory<OrderStatus>;
  totalPriceInclTaxes?: FieldHistory<number>;
  totalPriceExclTaxes?: FieldHistory<number>;
  totalDays?: FieldHistory<number>;
  startDate?: FieldHistory<string>;
  endDate?: FieldHistory<string>;
  firstName?: FieldHistory<string>;
  lastName?: FieldHistory<string>;
  email?: FieldHistory<string>;
  phone?: FieldHistory<string>;
  zipCode?: FieldHistory<string>;
  address?: FieldHistory<string>;
  city?: FieldHistory<string>;
  vehicleModel?: FieldHistory<string>;
  vehicleColor?: FieldHistory<string>;
}

export interface FieldHistory<T> {
  from: T;
  to: T;
}

// ORDER FORM FIELDS

export enum OrderFormikValues {
  siteId = "siteId",
  departureMeetingPointId = "departureMeetingPointId",
  arrivalMeetingPointId = "arrivalMeetingPointId",
  passengers = "passengers",
  arrivalFlightNumber = "arrivalFlightNumber",
  startDate = "startDate",
  endDate = "endDate",
  selectedStartDay = "selectedStartDay",
  selectedEndDay = "selectedEndDay",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  phone = "phone",
  zipCode = "zipCode",
  address = "address",
  city = "city",
  vehicleModel = "vehicleModel",
  vehicleColor = "vehicleColor",
  cancellationOption = "cancellationOption",
  lang = "lang",
  externalOrderNumber = "externalOrderNumber",
  isPlatform = "isPlatform",
  platformId = "platformId",
  luggage = "luggage",
  parkName = "parkName",
  parkLocation = "parkLocation",
}

export interface UpdateOrderFormikValues {
  [OrderFormikValues.siteId]: number;
  [OrderFormikValues.departureMeetingPointId]: number;
  [OrderFormikValues.arrivalMeetingPointId]: number;
  [OrderFormikValues.passengers]: number;
  [OrderFormikValues.arrivalFlightNumber]: string | null;
  [OrderFormikValues.startDate]: string;
  [OrderFormikValues.endDate]: string;
  [OrderFormikValues.selectedStartDay]: string;
  [OrderFormikValues.selectedEndDay]: string;
  [OrderFormikValues.firstName]: string;
  [OrderFormikValues.lastName]: string;
  [OrderFormikValues.email]: string;
  [OrderFormikValues.phone]: string;
  [OrderFormikValues.zipCode]: string;
  [OrderFormikValues.address]: string;
  [OrderFormikValues.city]: string;
  [OrderFormikValues.vehicleModel]: string;
  [OrderFormikValues.vehicleColor]: string;
  [OrderFormikValues.isPlatform]: boolean | undefined;
  [OrderFormikValues.platformId]: number | null;
  [OrderFormikValues.externalOrderNumber]: string | null;
  [OrderFormikValues.luggage]: string | null;
  [OrderFormikValues.parkName]: string | null;
  [OrderFormikValues.parkLocation]: string | null;
}

export interface CreateOrderFormikValues {
  [OrderFormikValues.siteId]: number | undefined;
  [OrderFormikValues.departureMeetingPointId]: number | undefined;
  [OrderFormikValues.arrivalMeetingPointId]: number | undefined;
  [OrderFormikValues.passengers]: number | undefined;
  [OrderFormikValues.arrivalFlightNumber]: string | null | undefined;
  [OrderFormikValues.startDate]: string | undefined;
  [OrderFormikValues.endDate]: string | undefined;
  [OrderFormikValues.selectedStartDay]: string | undefined;
  [OrderFormikValues.selectedEndDay]: string | undefined;
  [OrderFormikValues.firstName]: string | undefined;
  [OrderFormikValues.lastName]: string | undefined;
  [OrderFormikValues.email]: string | undefined;
  [OrderFormikValues.phone]: string | undefined;
  [OrderFormikValues.zipCode]: string | undefined;
  [OrderFormikValues.address]: string | undefined;
  [OrderFormikValues.city]: string | undefined;
  [OrderFormikValues.vehicleModel]: string | undefined;
  [OrderFormikValues.vehicleColor]: string | undefined;
  [OrderFormikValues.cancellationOption]: boolean;
  [OrderFormikValues.lang]: string;
  [OrderFormikValues.isPlatform]: boolean | undefined;
  [OrderFormikValues.externalOrderNumber]: string | undefined;
  [OrderFormikValues.platformId]: number | undefined;
}

// ORDER COMMENTS

export interface OrderComment {
  id: string;
  orderId: string;
  text: string;
  createdAt: string;
  author: string;
}
