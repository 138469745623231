import { Container } from "@mui/material";
import OrderCreationForm from "./Form";
import OrderCreationHeader from "./Header";

function OrderCreation() {
  return (
    <Container>
      <OrderCreationHeader />
      <OrderCreationForm />
    </Container>
  );
}

export default OrderCreation;
