import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderStatus } from "../../types/orders";
import { RootState } from "../store";

interface GridSortModel {
  field: string;
  sort: "asc" | "desc" | null | undefined;
}

interface FilterValue {
  status?: OrderStatus | undefined;
  site?: string | undefined;
  platform?: string | undefined;
}

export type OrdersListState = {
  debouncedSearchValue: string;
  replaceSearchValueByDebounced: boolean;
  filter: FilterValue;
  pageRowLimit: number;
  dateRange: DateRange<string>;
  activeSort: GridSortModel[];
};

const initialState: OrdersListState = {
  debouncedSearchValue: "",
  replaceSearchValueByDebounced: false,
  filter: {
    status: undefined,
    site: undefined,
    platform: undefined,
  },
  pageRowLimit: 30,
  dateRange: [null, null],
  activeSort: [{ field: "createdAt", sort: "desc" }],
};

export const ordersListSlice = createSlice({
  name: "ordersList",
  initialState,
  reducers: {
    setDebouncedSearchValue: (state, action: PayloadAction<string>) => {
      state.debouncedSearchValue = action.payload;
    },
    setReplaceSearchValueByDebounced: (state, action: PayloadAction<boolean>) => {
      state.replaceSearchValueByDebounced = action.payload;
    },
    setFilter: (state, action: PayloadAction<FilterValue>) => {
      state.filter = action.payload;
    },
    setRangeDateValue: (state, action: PayloadAction<DateRange<string>>) => {
      state.dateRange = action.payload;
    },
    setActiveSort: (state, action: PayloadAction<GridSortModel[]>) => {
      state.activeSort = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setDebouncedSearchValue,
  setReplaceSearchValueByDebounced,
  setFilter,
  setRangeDateValue,
  setActiveSort,
  resetState,
} = ordersListSlice.actions;

export const selectOrdersListState = (state: RootState) => state.ordersList;

export default ordersListSlice.reducer;
