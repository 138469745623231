export interface Site {
  id: number;
  active: boolean;
  label: i18nLabel;
  dailyBasePrice: number;
  slotDurationMinutes: number;
  departureOpeningTime: string;
  departureClosingTime: string;
  arrivalOpeningTime: string;
  arrivalClosingTime: string;
  shuttleDurationMinutes: number;
  meetingPoints: MeetingPoint[];
  phone: string;
  timezone: string;
  gpsCoordinates: string;
}

export interface MeetingPoint {
  id: number;
  name: i18nLabel;
  address: i18nLabel;
}

interface i18nLabel {
  [key: string]: string;
}

export enum SlotType {
  Departure = "departure",
  Arrival = "arrival",
}

export interface Slot {
  start: string;
  availablePassengers: number;
}

export interface ParkingCapacity {
  capacity: number;
  start_date: string;
  end_date: string;
}
