import { SvgIconComponent } from "@mui/icons-material";
import {
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
} from "@mui/lab";
import { Typography } from "@mui/material";
import { Palette } from "../../../../../../types/themes";

interface HistoryItemProps {
  date: string;
  author?: string;
  hideFirstConnector?: boolean;
  hideLastConnector?: boolean;
  Icon: SvgIconComponent;
  dotColor: Palette;
  children: JSX.Element;
}

const HistoryItem = ({
  date,
  author,
  hideFirstConnector,
  hideLastConnector,
  Icon,
  dotColor,
  children,
}: HistoryItemProps) => {
  return (
    <TimelineItem>
      <TimelineOppositeContent
        color="text.secondary"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <Typography>{date}</Typography>
        <Typography>{author}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector sx={{ opacity: hideFirstConnector ? 0 : "initial" }} />
        <TimelineDot sx={{ bgcolor: dotColor }}>
          <Icon />
        </TimelineDot>
        <TimelineConnector sx={{ opacity: hideLastConnector ? 0 : "initial" }} />
      </TimelineSeparator>
      {children}
    </TimelineItem>
  );
};

export default HistoryItem;
