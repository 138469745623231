import { Divider, Grid, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { TextField, Typography } from "@mui/material";
import {
  CreateOrderFormikValues,
  OrderFormikValues,
  UpdateOrderFormikValues,
} from "../../types/orders";

interface CustomerFormProps {
  isEditable: boolean;
  isRestrictedFieldsEditable: boolean;
  isEmailOptional?: boolean;
}

const CustomerForm = ({
  isEditable,
  isRestrictedFieldsEditable,
  isEmailOptional = false,
}: CustomerFormProps) => {
  const { errors, touched, handleChange, handleBlur, values, setFieldValue } = useFormikContext<
    UpdateOrderFormikValues | CreateOrderFormikValues
  >();

  return (
    <Stack sx={{ height: "100%" }}>
      <Typography sx={{ fontSize: 16, fontWeight: "bold", p: 3, textAlign: "center" }}>
        Customer
      </Typography>
      <Divider variant="middle" />
      <Grid container spacing={5} p={5}>
        <Grid item xs={6} md={6}>
          <TextField
            name={OrderFormikValues.firstName}
            label="First Name"
            value={values.firstName || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstName && !!errors.firstName}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            variant="standard"
            InputProps={{
              readOnly: !isRestrictedFieldsEditable,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name={OrderFormikValues.lastName}
            label="Last Name"
            value={values.lastName || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastName && !!errors.lastName}
            fullWidth
            helperText={touched.lastName && errors.lastName}
            variant="standard"
            InputProps={{
              readOnly: !isRestrictedFieldsEditable,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name={OrderFormikValues.email}
            label={`Email ${isEmailOptional ? "(Optional)" : ""}`}
            value={values.email || ""}
            onChange={(e) => setFieldValue(e.target.name, e.target.value || undefined)}
            error={touched.email && !!errors.email}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            type="email"
            variant="standard"
            InputProps={{
              readOnly: !isEditable,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            name={OrderFormikValues.phone}
            label="Phone"
            value={values.phone || ""}
            onChange={handleChange}
            error={touched.phone && !!errors.phone}
            fullWidth
            helperText={touched.phone && errors.phone}
            onBlur={handleBlur}
            type="text"
            variant="standard"
            InputProps={{
              readOnly: !isEditable,
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            name={OrderFormikValues.address}
            label="Address"
            value={values.address || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.address && !!errors.address}
            fullWidth
            helperText={touched.address && errors.address}
            type="text"
            variant="standard"
            InputProps={{
              readOnly: !isRestrictedFieldsEditable,
            }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TextField
            name={OrderFormikValues.city}
            label="City"
            value={values.city || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.city && !!errors.city}
            fullWidth
            helperText={touched.city && errors.city}
            type="text"
            variant="standard"
            InputProps={{
              readOnly: !isRestrictedFieldsEditable,
            }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TextField
            fullWidth
            name={OrderFormikValues.zipCode}
            label="Zip Code"
            value={values.zipCode || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.zipCode && !!errors.zipCode}
            helperText={touched.zipCode && errors.zipCode}
            variant="standard"
            InputProps={{
              readOnly: !isRestrictedFieldsEditable,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name={OrderFormikValues.vehicleModel}
            label="Vehicle Model"
            value={values.vehicleModel || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.vehicleModel && !!errors.vehicleModel}
            helperText={touched.vehicleModel && errors.vehicleModel}
            variant="standard"
            InputProps={{
              readOnly: !isEditable,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name={OrderFormikValues.vehicleColor}
            label="Vehicle Color"
            value={values.vehicleColor || ""}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.vehicleColor && !!errors.vehicleColor}
            helperText={touched.vehicleColor && errors.vehicleColor}
            variant="standard"
            inputProps={{ maxLength: 32 }}
            InputProps={{
              readOnly: !isEditable,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CustomerForm;
