import { WatchLaterOutlined } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import moment from "moment";
import { FocusEventHandler } from "react";
import { Slot } from "../../../../types/sites";
import {
  convertMomentDateToZonedDateTime,
  convertZonedDateTimeToMomentDate,
  formatFromZonedDateTime,
} from "../../../../utils/date";

interface SlotInputProps {
  onChange: (e: SelectChangeEvent) => void;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string | undefined;
  initialValue: string | undefined;
  initialDay: string | undefined;
  selectedDay: string | undefined;
  slots: Slot[] | undefined;
  isEditable: boolean;
  inputLabel: string;
  inputName: string;
  errorMessage: string | undefined;
}

const SlotInput = ({
  onChange,
  onBlur,
  initialValue,
  value,
  slots,
  selectedDay,
  initialDay,
  isEditable,
  inputLabel,
  inputName,
  errorMessage,
}: SlotInputProps) => {
  const generateSlotOptions = () => {
    const slotOptions = slots?.map((slot: Slot, index: number) => {
      const isFull = slot.availablePassengers === 0;
      return (
        <MenuItem disabled={isFull} value={slot.start} key={index}>
          {formatFromZonedDateTime(slot.start, "HH:mm")}{" "}
          {`(${slot.availablePassengers} passengers available)`}
        </MenuItem>
      );
    });

    if (!!initialValue) {
      const selectedDayIsToday = moment(selectedDay).isSame(moment(), "day");

      const initialSlot = convertZonedDateTimeToMomentDate(initialValue);

      const dateTimezone = initialSlot?.tz();
      const now = dateTimezone ? moment.tz(dateTimezone) : moment();
      const startOfTheDay = dateTimezone
        ? moment.tz(dateTimezone).startOf("day")
        : moment().startOf("day");

      if (selectedDayIsToday && initialSlot?.isBetween(startOfTheDay, now)) {
        slotOptions?.unshift(
          <MenuItem
            value={convertMomentDateToZonedDateTime(initialSlot)}
            key={slotOptions?.length + 1}
          >
            {initialSlot.format("HH:mm")}
          </MenuItem>
        );
      }
    }

    return slotOptions;
  };

  return (
    <FormControl error={!!errorMessage} fullWidth variant="standard">
      <InputLabel>{inputLabel}</InputLabel>
      <Select
        readOnly={!isEditable}
        IconComponent={WatchLaterOutlined}
        inputProps={{ name: inputName }}
        value={value ? `${value}` : ""}
        onChange={onChange}
        onBlur={onBlur}
      >
        {slots?.length
          ? generateSlotOptions()
          : initialValue &&
            initialDay === selectedDay && (
              <MenuItem value={initialValue}>
                {formatFromZonedDateTime(initialValue, "HH:mm")}
              </MenuItem>
            )}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

export default SlotInput;
