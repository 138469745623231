import React from "react";
import { Typography } from "@mui/material";

import { Site } from "../../types/sites";
import { labelLanguage } from "../../config";

interface CellPropsType {
  value: number;
  sites: Array<Site>;
}

function CustomCellSite({ value, sites }: CellPropsType) {
  const siteLabel = sites?.find((site: Site) => site.id === value)?.label[labelLanguage];

  return <Typography>{siteLabel}</Typography>;
}

export default CustomCellSite;
